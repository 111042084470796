import { Alert, AlertColor, AlertPropsColorOverrides, AlertTitle, Card, Container, Stack } from "@mui/material";
import { OverridableStringUnion } from "@mui/types";
import Typography from "@mui/material/Typography";
import React, { useEffect } from "react";

export function Status() {

  // Alert state for DHCP Service
  const [dhcpService, setDhcpService] = React.useState<OverridableStringUnion<AlertColor, AlertPropsColorOverrides>>("info");

  useEffect(() => {
    // Fetch DHCP service status from https://apim-api-gw.apps.eviny.no/dhcpstatus/v1.0/status
    fetch("https://apim-api-gw.apps.eviny.no/dhcpstatus/v1.0/status")
      .then(response => response.json())
      .then(data => {
        if (data.status === "ok") {
          setDhcpService("success");
        } else {
          setDhcpService("error");
        }
      })
      .catch(error => {
        console.error("Error fetching DHCP service status", error);
        setDhcpService("error");
      });
  }, []);

  return (
    <Container>
      <Typography variant="h1">Operational Status</Typography>
      <Card style={{padding: "1em"}}>
        <Typography variant="h2">OSS</Typography>
        <Stack>
          <Alert severity={dhcpService}>
            <AlertTitle>DHCP Service</AlertTitle>
          </Alert>
        </Stack>
      </Card>
    </Container>
  );
}
