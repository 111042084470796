import { useEffect, useState } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import Typography from "@mui/material/Typography";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
    Link
  } from "@mui/material";

const WelcomeName = () => {
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [name, setName] = useState("");

    useEffect(() => {
        if (account && account.name) {
            setName(account.name.split(" ")[0]);
        } else {
            setName("");
        }
    }, [account]);

    if (name) {
        return <Typography variant="h6">Hei, <Profile account={name}/>!</Typography>;
    } else {
        return null;
    }
};

export function Profile({account}) {
    return (
        <Link
            component={RouterLink}
            to="/profile"
            color="inherit"
            variant="h6"
            underline="none"
        >
            {account}
        </Link>
    );
}

export default WelcomeName;