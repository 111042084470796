import React, { useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { Tooltip } from "@mui/material"; // Make sure to import Tooltip
import {
  ErrorOutline,
  CheckCircleOutline,
  Unpublished,
  Pending,
  Sync,
  AddCircle,
  ChangeCircle,
  RemoveCircle,
  Engineering,
  LinearScale,
} from "@mui/icons-material";
import { ConfigureResponse } from "../gen/cfsInventoryClient/types.gen";

export const StatusIconConfigResponse = ({
  configResponse,
  suppressToolTip,
}: {
  configResponse: ConfigureResponse;
  suppressToolTip?: boolean;
}) => {
  let icon = (
    <Unpublished sx={{ color: "rgb(200, 200, 200)" }} fontSize="small" />
  );
  const toolTipTitle =
    configResponse.status_message ?? configResponse.status ?? "Unknown";
  switch (configResponse.status) {
    case "COMPLETED":
      icon = (
        <CheckCircleOutline sx={{ color: "rgb(0, 200, 0)" }} fontSize="small" />
      );
      break;
    case "FAILED":
      icon = <ErrorOutline sx={{ color: "rgb(200, 0, 0)" }} fontSize="small" />;
      break;
    case "WORKING":
      icon = <Sync sx={{ color: "rgb(0, 0, 200)" }} fontSize="small" />;
      break;
    case "ACCEPTED":
      icon = <Pending sx={{ color: "rgb(255, 216, 0)" }} fontSize="small" />;
      break;
    case "PENDING":
      icon = <Pending sx={{ color: "rgb(255, 216, 0)" }} fontSize="small" />;
      break;
    case "INITIALIZED":
      icon = <Pending sx={{ color: "rgb(255, 216, 0)" }} fontSize="small" />;
      break;
  }
  if (!suppressToolTip) {
    return <Tooltip title={toolTipTitle}>{icon}</Tooltip>;
  } else {
    return icon;
  }
};

export const ConfigResponseStatusErrors = ({
  serviceName,
  configresponseMap,
}: {
  serviceName: string;
  configresponseMap: Map<string, Array<ConfigureResponse>>;
}) => {
  const configResponses = Array.from(configresponseMap.values()).flat().flat();
  // Ref to keep track of the IDs of the responses that have already triggered a toast message
  const sentToastMessages = useRef<Map<string, string>>(new Map());

  // Check if any response has a status of FAILED or PENDING/ACCEPTED with a status_message not equal to "PENDING/ACCEPTED"
  useEffect(() => {
    configResponses
      .filter(
        (cr) =>
          cr.status === "FAILED" ||
          (cr.status === "PENDING" && cr.status_message !== "Pending") ||
          (cr.status === "ACCEPTED" && cr.status_message !== "Accepted"),
      )
      .forEach((failedResponse) => {
        if (!failedResponse.id) {
          failedResponse.id = "";
        }
        if (!failedResponse.status_message) {
          failedResponse.status_message = "";
        }
        const previousStatusMessage = sentToastMessages.current.get(
          failedResponse.id,
        );
        if (previousStatusMessage !== failedResponse.status_message) {
          toast.error(
            <>
              <h3>{serviceName}</h3>
              <strong>
                Template {failedResponse.template_name ?? "Unknown"} FAILED:
              </strong>
              <br />
              {failedResponse.status_message}
            </>,
            {
              position: "top-right",
              autoClose: false, // Never timeout
              closeOnClick: false,
              draggable: false,
              theme: "colored",
            },
          );
          sentToastMessages.current.set(
            failedResponse.id,
            failedResponse.status_message,
          );
        }
      });
  }, [configResponses]);
};

export const StatusIconConfigResponsesAggregated = ({
  configResponsesMap,
}: {
  configResponsesMap: Map<string, Array<ConfigureResponse>>;
}) => {
  const configResponses = Array.from(configResponsesMap.values()).flat().flat();
  if (configResponses.length === 0) return null;
  // Initialize a map to hold counts for each status type
  const statusCounts = configResponses.reduce((acc, cr) => {
    if (cr.status !== undefined) {
      acc[cr.status] = (acc[cr.status] || 0) + 1;
    }
    return acc;
  }, {});

  // Function to generate tooltip based on status and count
  const generateToolTip = (status: string, count: number) => {
    if (count === 1) {
      const singleResponse = configResponses.find((cr) => cr.status === status);
      const templateName = singleResponse?.template_name ?? "Unknown Template";
      return `${status}: ${templateName}`;
    } else {
      return `${status}: ${count} Templates`;
    }
  };

  const statusOrder = [
    "FAILED",
    "UNSPECIFIED",
    "INITIALIZED",
    "ACCEPTED",
    "PENDING",
    "WORKING",
  ];

  // Sort the entries of statusCounts based on the defined order
  const sortedStatusCounts = Object.entries(statusCounts).sort(
    (a, b) => statusOrder.indexOf(a[0]) - statusOrder.indexOf(b[0]),
  );

  // Accumulate the JSX elements for each status
  const icons = sortedStatusCounts.map(([status, countString]) => {
    const count = parseInt(countString);
    const toolTip = generateToolTip(status, count);
    const cr: ConfigureResponse = {
      status: status as
        | "UNSPECIFIED"
        | "ACCEPTED"
        | "PENDING"
        | "WORKING"
        | "COMPLETED"
        | "INITIALIZED"
        | "FAILED"
        | undefined,
    };
    return (
      <Tooltip title={toolTip} key={status}>
        <div style={{ display: "inline-flex" }}>
          <StatusIconConfigResponse
            configResponse={cr}
            suppressToolTip={true}
          />
        </div>
      </Tooltip>
    );
  });

  // Return the accumulated JSX elements
  // If there are no statuses to display, return a default icon or null
  return icons.length > 0 ? (
    <>{icons}</>
  ) : (
    <StatusIconConfigResponse configResponse={{}} />
  );
};

export const ChangeNatureIcon = ({
  configResponse,
  suppressToolTip,
}: {
  configResponse: ConfigureResponse;
  suppressToolTip?: boolean;
}) => {
  let icon = (
    <Unpublished sx={{ color: "rgb(200, 200, 200)" }} fontSize="small" />
  );
  let toolTipTitle = "Unknown";
  const color = "rgb(200, 200, 200)"; // grey
  switch (configResponse.change_nature) {
    case "SETUP":
      icon = <AddCircle sx={{ color }} fontSize="small" />;
      toolTipTitle = "Setup";
      break;
    case "MODIFICATION":
      icon = <ChangeCircle sx={{ color }} fontSize="small" />;
      toolTipTitle = "Modification";
      break;
    case "REMOVE":
      icon = <RemoveCircle sx={{ color }} fontSize="small" />;
      toolTipTitle = "Remove";
      break;
    case "MAINTENANCE":
      icon = <Engineering sx={{ color }} fontSize="small" />;
      toolTipTitle = "Maintenance";
      break;
    case "NOCHANGE":
      icon = <LinearScale sx={{ color }} fontSize="small" />;
      toolTipTitle = "No Change";
      break;
  }
  if (!suppressToolTip) {
    return <Tooltip title={toolTipTitle}>{icon}</Tooltip>;
  } else {
    return icon;
  }
};
