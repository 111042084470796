import React, { useEffect } from "react";
import { useState } from "react";
import { OpenAPI } from "../../gen/ipamClient/core/OpenAPI.ts";
import { AddressSpaceService } from "../../gen/ipamClient/services.gen";
import { ipamConfig } from "../../authConfig.ts";
import { acquireAccessToken } from "../../utils/auth.ts";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

OpenAPI.BASE = ipamConfig.baseUrl;
//OpenAPI.TOKEN = await acquireAccessToken(ipamConfig.scope);

export function Addressspaces() {
  const [addressSpaces, setAddressSpaces] =
    useState<
      Awaited<ReturnType<typeof AddressSpaceService.getAddressspaces>>
    >();

  const onFetchAddressSpaces = async () => {
    OpenAPI.TOKEN = await acquireAccessToken(ipamConfig.scope);
    const addressSpaces = await AddressSpaceService.getAddressspaces();
    setAddressSpaces(addressSpaces);
  };
  useEffect(() => {
    onFetchAddressSpaces();
  }, []);
  return (
    <div>
      <Typography variant="h6" align="center">
        Addressspaces
      </Typography>
      {addressSpaces && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Addressspace</TableCell>
                <TableCell>IPv4/IPv6</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {addressSpaces.address_spaces?.map((addressspace) => (
                <TableRow
                  component={Link}
                  to={`/ipam/addressspace/${addressspace.id}`}
                  key={addressspace.id}
                >
                  <TableCell>{addressspace.address}</TableCell>
                  <TableCell>Ipv{addressspace.version}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter></TableFooter>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}
