import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  CircularProgress,
  Divider,
  TableCell,
  TableRow,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";
import {
  fetchManagementEquipmentByResourceEquipmentId,
  fetchResourceEquipmentById,
} from "../utils/inventory/equipment";
import {
  ResourceEquipment,
  ResourceEquipmentPort,
  MgmtEquipment,
} from "../gen/cfsInventoryClient/types.gen";
import SettingsEthernetIcon from "@mui/icons-material/SettingsEthernet";
import LanIcon from "@mui/icons-material/Lan";
import { Popover, Button } from "@mui/material";

interface EquipmentProps {
  id: string;
}

const getIconColor = (state) => {
  switch (state) {
    case "RESERVED":
      return "blue";
    case "UNUSED":
      return "green";
    default:
      return "gray";
  }
};

export const Equipment: React.FC<EquipmentProps> = ({ id }) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [portList, setPortList] = useState<
    Array<ResourceEquipmentPort> | undefined
  >(undefined);
  const [resourceEquipment, setResourceEquipment] = useState<
    ResourceEquipment | undefined
  >(undefined);
  const [mgmtEquipment, setMgmtEquipment] = useState<MgmtEquipment | undefined>(
    undefined,
  );

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const resourceEquipment = await fetchResourceEquipmentById(id);
      setResourceEquipment(resourceEquipment);
      setPortList(resourceEquipment?.ports);

      const mgmtEquipment =
        await fetchManagementEquipmentByResourceEquipmentId(id);
      setMgmtEquipment(mgmtEquipment);
      setLoading(false);
    };
    fetchData();
  }, [id]);

  const handleIconClick = async () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <>
      <TableRow
        key={id}
        style={{ backgroundColor: theme.palette.background.default }}
      >
        <TableCell style={{ paddingLeft: "10px" }}>
          {loading ? <CircularProgress /> : null}
          {expanded && !loading ? (
            <ExpandLessIcon onClick={handleIconClick} />
          ) : (
            <ExpandMoreIcon onClick={handleIconClick} />
          )}
        </TableCell>
        <TableCell style={{ paddingLeft: "30px" }}>
          <LanIcon style={{ color: "blue" }} />
        </TableCell>
        <TableCell>
          <div>Host name: {resourceEquipment?.host_name}</div>
          <div>Management IP: {mgmtEquipment?.mgmt_ip} </div>
          <div>Loopback IP: {resourceEquipment?.loopback_ip} </div>
          {resourceEquipment?.external_ids && (
            <Box>
              <Divider />
              External system id's:
              {resourceEquipment?.external_ids.map((id, index) => (
                <div key={index}>
                  {id.system_name} : {id.external_id}
                </div>
              ))}
            </Box>
          )}
        </TableCell>
        <TableCell>
          <div>Vendor: {mgmtEquipment?.vendor}</div>
          <div>Model: {mgmtEquipment?.model}</div>
          <div>Software Version: {mgmtEquipment?.software_version}</div>
          <Divider />
          <div>State: {resourceEquipment?.state}</div>
          <Divider />
          Capabilities:
          {resourceEquipment?.capability?.map((capability, index) => (
            <div key={index}>{capability}</div>
          ))}
        </TableCell>
        <TableCell></TableCell>
      </TableRow>
      {portList && expanded && (
        <>
          {portList.map((port) => (
            <TableRow
              key={port.id}
              style={{ background: theme.palette.background.default }}
            >
              <TableCell></TableCell>
              <TableCell style={{ paddingLeft: "50px" }}>
                <SettingsEthernetIcon
                  style={{ color: getIconColor(port.state) }}
                />
              </TableCell>
              <TableCell>{port.display_name}</TableCell>
              <TableCell>{port.state}</TableCell>
              <TableCell>{port.reservation_ext_reference}</TableCell>
            </TableRow>
          ))}
        </>
      )}
    </>
  );
};

const EquipmentSummaryPortal = ({ id, name, portalPosition }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [portalReady, setPortalReady] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    const portalRoot = document.createElement("div");
    portalRoot.id = "portal-root";
    document.body.appendChild(portalRoot);
    setPortalReady(true);

    // Cleanup function to remove the portal-root when the component unmounts
    return () => {
      portalRoot?.remove();
    };
  }, []);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  // Conditionally render the portal content only when the portal-root is ready
  return (
    <div>
      <div onClick={toggleExpand}>
        {name}
        {isExpanded ? (
          <ArrowDropUp onClick={toggleExpand} />
        ) : (
          <ArrowDropDown onClick={toggleExpand} />
        )}
      </div>
      {isExpanded && portalReady ? (
        <div
          style={{
            position: "absolute", // Use 'fixed' to make it float relative to the viewport
            left: `${portalPosition.left}px`, // Position horizontally based on the calling element
            top: `${portalPosition.top}px`, // Position vertically just below the calling element
            backgroundColor: theme.palette.background.paper, // Use theme background color
            color: theme.palette.text.primary, // Use theme text color
            padding: "10px", // Some padding around the content
            boxShadow: theme.shadows[4], // Use theme shadow
            zIndex: 1000, // Ensure the portal content is above other elements
          }}
        >
          <Equipment id={id} />
        </div>
      ) : null}
    </div>
  );
};

interface EquipmentSummaryWithPopoverProps {
  name: string;
  id: string;
  direct?: boolean;
}

export const EquipmentSummaryWithPopover: React.FC<
  EquipmentSummaryWithPopoverProps
> = ({ name, id, direct }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (direct) {
      setAnchorEl(document.body); // Set anchorEl to a non-null value to open the popover
    }
  }, [direct]);

  const open = Boolean(anchorEl);
  const popoverId = open ? "simple-popover" : undefined;

  return (
    <>
      {!direct && <Button onClick={handleClick}>{name}</Button>}
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div
          style={{
            border: "1px solid #ccc",
            backgroundColor: "#fff",
          }}
        >
          <Equipment id={id} />
        </div>
      </Popover>
    </>
  );
};

export default Equipment;
