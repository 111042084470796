import React from "react";
import ReactDOMServer from "react-dom/server";

const ConvertComponentToDataURL = async (Component: React.ComponentType) => {
  try {
    const svgString = ReactDOMServer.renderToString(<Component />);
    const svgBlob = new Blob([svgString], {
      type: "image/svg+xml;charset=utf-8",
    });
    const url = URL.createObjectURL(svgBlob);
    const img = new Image();
    img.src = url;
    await new Promise((resolve) => {
      img.onload = () => {
        resolve(null);
      };
    });
    const canvas = document.createElement("canvas");
    canvas.width = 82;
    canvas.height = 82;
    const ctx = canvas.getContext("2d");
    if (ctx) {
      ctx.drawImage(img, 0, 0);
      URL.revokeObjectURL(url);
      return canvas.toDataURL();
    }
  } catch (error) {
    console.error("Error converting component to data URL:", error);
  }
  return "";
};

export default ConvertComponentToDataURL;
