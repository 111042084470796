import { configureOpenAPI } from "../../utils/openApi";
import { OpenAPI, getPainData, GetPainDataResponse } from "../../gen/painClient";
import { toast } from "react-toastify";

 
export const fetchPainData = async (
    id: string,
): Promise<GetPainDataResponse | undefined> => {
    try {
        await configureOpenAPI(OpenAPI);
        const response = await getPainData({id, stopAtCoreSite: true});
        return response;
    } catch (error) {
        toast.error("Error fetching pain data");
        return undefined;
    }
};
    

export default fetchPainData;
