import { Routes, Route, useNavigate } from "react-router-dom";
// Material-UI imports
import Grid from "@mui/material/Grid";

// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { CustomNavigationClient } from "./utils/NavigationClient";

// Sample app imports
import { PageLayout } from "./ui-components/PageLayout";
import { Home } from "./pages/Home";
import { Profile } from "./pages/Profile";
import { Points } from "./pages/Points";
import { PainVisualisation, PainAdjacency } from "./pages/Pain";
import React from "react";
import Addresses from "./pages/Addresses";
import Services from "./pages/Services";
import { Addressspaces } from "./pages/Ipam/Addressspaces";
import InventoryImport from "./pages/InventoryImport";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { Status } from "./pages/Status";

type AppProps = {
  pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {
  // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={pca}>
      {/* <ThemeContextProvider> */}
      <PageLayout>
        <Grid container justifyContent="center">
          <AuthenticatedTemplate>
            <Pages />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <div>Log inn for å se ting og tang.</div>
          </UnauthenticatedTemplate>
        </Grid>
      </PageLayout>
      {/* </ThemeContextProvider> */}
    </MsalProvider>
  );
}

function Pages() {
  return (
    <Routes>
      <Route path="/profile" element={<Profile />} />
      <Route path="/" element={<Home />} />
      <Route path="/points" element={<Points />} />
      <Route path="/addresses" element={<Addresses />} />
      <Route path="/services/:serviceName?" element={<Services />} />
      <Route path="/ipam" element={<Addressspaces />} />
      <Route path="/pain/adjacency" element={<PainAdjacency />} />
      <Route path="/pain" element={<PainVisualisation />} />
      <Route path="/inventory/import" element={<InventoryImport />} />
      <Route path="/status" element={<Status />} />
    </Routes>
  );
}

export default App;
