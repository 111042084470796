import React, { useState, useRef, useEffect } from "react";
import {
  CircularProgress,
  Alert,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import SearchBox from "../ui-components/SearchBox";
import { Button } from "@mui/material";
import { fetchPainData } from "../utils/pain/painUI";
import { Adjacency, GetPainDataResponse } from "../gen/painClient";
import { toast } from "react-toastify";
import { Equipment } from "../ui-components/Equipment";
import fetchPainAdjacencies, {
  NodeAdjacencies,
} from "../utils/pain/painAdjacencies";
import {
  ServiceRouterIcon,
  SwitchIcon,
  MPLSRouterIcon,
  GenericSoftSwitchIcon,
} from "../ui-components/ServiceTopologyIcons";
import DropdownList from "../ui-components/DropDownList";
import { useTheme } from "@mui/material/styles";
import ConvertComponentToDataURL from "../utils/convertComponentToDataUrl";
import * as d3 from "d3";
import Clippy, {
  defaultRandomActionTime,
  ClippyProps,
} from "../ui-components/clippy";
import { fetchResourceEquipmentExternalIdsByExternalIds } from "../utils/inventory/identify";
import {
  CfExternalSystem,
  ResourceEquipment,
} from "../gen/cfsInventoryClient/types.gen";
import { fetchResourceEquipmentById } from "../utils/inventory/equipment";

type Layout = "" | "Tree" | "Radial";
const layoutOptions: Layout[] = ["", "Tree", "Radial"];
const defaultLayout = "" as Layout;

type extIdMap = {
  [key: string]: CfExternalSystem[];
};

type nodeDetailsMap = {
  [key: string]: ResourceEquipment | undefined;
};

type adj = {
  local: Adjacency | undefined;
  adjacents: Adjacency[] | undefined;
};

type selectedNode = {
  invId: string | undefined;
  nodeName: string;
};

type Edge = {
  from: string;
  to: string;
  [key: string]: any;
};

type Node = {
  id: string;
  parentId?: string;
  [key: string]: any;
};

export const PainVisualisation = () => {
  const [painData, setPainData] = useState<GetPainDataResponse | undefined>(
    undefined,
  );
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const graphRef = useRef<HTMLDivElement>(null);
  const [layout, setLayout] = useState<string>(defaultLayout);
  const [clippyState, setClippyState] =
    useState<ClippyProps["state"]>("pointRight");
  const [ClippyRandomActionTime, setClippyRandomActionTime] = useState(
    defaultRandomActionTime,
  );
  const [id, setId] = useState<string>("");
  const [extIds, setExtIds] = useState<extIdMap>({});
  const [nodeDetails, setNodeDetails] = useState<nodeDetailsMap>({});
  const [linkDetails, setLinkDetails] = useState<adj[]>([]);
  const [selectedNode, setSelectedNode] = useState<selectedNode | null>(null);
  const [selectedLink, setSelectedLink] = useState<any | null>(null);
  const theme = useTheme();

  useEffect(() => {
    if (error) {
      toast.error(error);
      setClippyState("warning");
      setClippyRandomActionTime(0);
    } else if (loading) {
      setClippyState("looking");
      setClippyRandomActionTime(0);
    } else if (success) {
      setClippyState("success");
      setClippyRandomActionTime(0);
    } else {
      setClippyState("pointRight");
      setClippyRandomActionTime(defaultRandomActionTime);
    }
  }, [error, loading]);

  const handleSearch = () => {
    let pain: GetPainDataResponse | undefined = {};
    const extIds: extIdMap = {};
    const nodeDetails = {};
    if (!id) {
      return;
    }
    const load = async () => {
      setLoading(true);
      setError(null);
      await fetchPain();
      await fetchExternalIds();
      await fetchAllNodeDetails();
      setLoading(false);
      setSuccess(true);
    };

    const fetchPain = async () => {
      pain = await fetchPainData(id);
      if (pain) {
        setPainData(pain);
      } else {
        setError("Error fetching Pain data");
      }
    };

    const fetchExternalIds = async () => {
      for (const node of pain?.nodes ?? []) {
        const id = node.label ?? "";
        if (extIds[id]) {
          continue;
        }
        if (id == "") {
          continue;
        }
        const ids = await fetchResourceEquipmentExternalIdsByExternalIds([
          node.label ?? "",
        ]);
        if (!ids) {
          console.error("Could not find external IDs for:", node.label);
          continue;
        }
        extIds[id] = ids;
      }
      setExtIds(extIds);
    };

    const fetchAllNodeDetails = async () => {
      for (const [id, exts] of Object.entries(extIds)) {
        for (const ext of exts) {
          if (ext.system_name === "INVENTORY") {
            if (ext.external_id && ext.external_id !== "") {
              nodeDetails[id] = await fetchResourceEquipmentById(
                ext.external_id,
              );
            }
          }
        }
      }
      setNodeDetails(nodeDetails);
    };

    load();
  };

  useEffect(() => {
    const getAdjacencies = async (source: string, dest: string) => {
      const linkDetails: adj[] = [];
      const adjacencies = await fetchPainAdjacencies(source);
      for (const adj of adjacencies) {
        for (const adjNode of adj.adjacents ?? []) {
          if (adjNode.node === dest) {
            linkDetails.push(adj);
          }
        }
      }
      setLinkDetails(linkDetails);
    };
    if (selectedLink?.source?.id && selectedLink?.target?.id) {
      getAdjacencies(selectedLink.source.id, selectedLink.target.id);
    }
  }, [selectedLink]);

  const getInventoryId = (id: string): string | undefined => {
    const extId = extIds[id];
    if (extId) {
      for (const id of extId) {
        if (id.system_name === "INVENTORY") {
          return id.external_id;
        }
      }
    }
    return "";
  };

  const getNodeName = (id: string) => {
    const extId = extIds[id];
    let dns: string | undefined;
    let mgtmt: string | undefined;
    if (extId) {
      for (const id of extId) {
        if (id.system_name === "DNS") {
          dns = id.external_id;
        }
        if (id.system_name === "MANAGEMENT") {
          mgtmt = id.external_id;
        }
      }
      if (dns) {
        return dns;
      }
      if (mgtmt) {
        return mgtmt;
      }
      return id;
    }
    return id;
  };

  useEffect(() => {
    const initializeNetwork = async () => {
      if (painData && graphRef.current) {
        const svg = d3
          .select(graphRef.current)
          .append("svg")
          .attr("width", "100%")
          .attr("height", "100%");

        const width = graphRef.current.clientWidth;
        const height = graphRef.current.clientHeight;

        // Ensure each node has a unique id
        const nodes = painData?.nodes?.map((node) => ({
          ...node,
          id: node.id ? node.id.toString() : "",
        }));

        // Ensure edges reference valid node ids
        const edges = painData?.edges?.map((edge) => ({
          ...edge,
          source: (edge.from ?? "").toString(),
          target: (edge.to ?? "").toString(),
        }));

        // Layout functions and other initializations...

        // Create a group to contain all elements
        const graphGroup = svg.append("g");

        // Apply zoom behavior to the svg group
        const zoom = d3.zoom().on("zoom", (event) => {
          graphGroup.attr("transform", event.transform);
        });

        // Apply the zoom behavior to the svg
        svg.call(zoom);

        const applyTreeLayout = (nodes: Node[], edges: Edge[]) => {
          const root = d3
            .stratify<Node>()
            .id((d: Node) => d.id)
            .parentId((d: Node) => d.parentId)(nodes);

          const treeLayout = d3.tree<Node>().size([width, height]);
          treeLayout(root);

          root.descendants().forEach((d: d3.HierarchyPointNode<Node>) => {
            d.x = d.x;
            d.y = d.y;
          });

          return { nodes: root.descendants(), edges };
        };

        const applyRadialLayout = (nodes, edges) => {
          const root = d3
            .stratify()
            .id((d: any) => d.id)
            .parentId((d: any) => d.parentId)(nodes);

          const radialTreeLayout = d3
            .tree()
            .size([2 * Math.PI, Math.min(width, height) / 2]);
          radialTreeLayout(root);

          root.descendants().forEach((d: any) => {
            const radius = d.y;
            const angle = d.x;
            d.x = radius * Math.cos(angle);
            d.y = radius * Math.sin(angle);
          });

          return { nodes: root.descendants(), edges };
        };

        const applyLayout = (layout, nodes, edges) => {
          switch (layout) {
            case "Tree":
              return applyTreeLayout(nodes, edges);
            case "Radial":
              return applyRadialLayout(nodes, edges);
            default:
              return { nodes, edges };
          }
        };

        const switchIcon = await ConvertComponentToDataURL(SwitchIcon);
        const mplsRouterIcon = await ConvertComponentToDataURL(MPLSRouterIcon);
        const serviceRouterIcon =
          await ConvertComponentToDataURL(ServiceRouterIcon);
        const unknownIcon = await ConvertComponentToDataURL(() => (
          <GenericSoftSwitchIcon fill={"#808080"} />
        ));
        const selectNodeIcon = (id: string) => {
          if (nodeDetails[id] && nodeDetails[id].capability) {
            // Find node icon based on highest priority capability
            for (const capability of nodeDetails[id].capability) {
              switch (capability) {
                case "L3_INTERNET":
                case "L3_VPN":
                  return serviceRouterIcon;
                case "L2":
                  return mplsRouterIcon;
                case "ACCESS":
                  return switchIcon;
              }
            }
          }
          return unknownIcon;
        };

        const { nodes: layoutNodes, edges: layoutEdges } = applyLayout(
          layout,
          nodes,
          edges,
        );

        const simulation = d3
          .forceSimulation(layoutNodes)
          .force(
            "link",
            d3
              .forceLink(layoutEdges)
              .id((d: any) => d.id)
              .distance(200),
          )
          .force("charge", d3.forceManyBody().strength(-150)) // Increase repulsion
          .force("center", d3.forceCenter(width / 2, height / 2));

        const link = graphGroup
          .append("g")
          .attr("class", "links")
          .selectAll("g")
          .data(layoutEdges)
          .enter()
          .append("g");

        // Append invisible line for capturing click events
        link
          .append("line")
          .attr("class", "clickable-line")
          .attr("stroke", "transparent")
          .attr("stroke-width", 10)
          .on("click", function (event: any, d: any) {
            setSelectedLink(d); // Set the clicked link's data
            svg.selectAll(".link-line").attr("stroke-opacity", 0.6);
            d3.select(this.parentNode)
              .select(".link-line")
              .attr("stroke-opacity", 1);
          });

        // Append visible line
        link
          .append("line")
          .attr("class", "link-line")
          .attr("stroke", "#999")
          .attr("stroke-opacity", 0.6)
          .attr("stroke-width", (d: any) => d.width);

        const node = graphGroup
          .append("g")
          .attr("class", "nodes")
          .selectAll("g")
          .data(layoutNodes)
          .enter()
          .append("g")
          .attr("class", "node-group");

        // Append circle for highlighting when selected
        node
          .append("circle")
          .attr("r", 12)
          .attr("cx", (d: any) => d.x)
          .attr("cy", (d: any) => d.y)
          .attr("fill", "none")
          .attr("stroke", "red")
          .attr("stroke-width", 2)
          .attr("visibility", "hidden");

        // Append image
        node
          .append("image")
          .attr("xlink:href", (d: any) => selectNodeIcon(d.id)) // Set the path to your SVG icon
          .attr("width", 20)
          .attr("height", 20)
          .attr("x", (d: any) => d.x - 10)
          .attr("y", (d: any) => d.y - 10)
          .on("click", function (event: any, d: any) {
            setSelectedNode({
              invId: getInventoryId(d.id),
              nodeName: getNodeName(d.id),
            });
            svg.selectAll(".node-group circle").attr("visibility", "hidden");
            d3.select(this.parentNode)
              .select("circle")
              .attr("visibility", "visible");
          })
          .call(
            d3
              .drag()
              .on("start", (event: any, d: any) => {
                if (!event.active) simulation.alphaTarget(0.3).restart();
                d.fx = d.x;
                d.fy = d.y;
              })
              .on("drag", (event: any, d: any) => {
                d.fx = event.x;
                d.fy = event.y;
              })
              .on("end", (event: any, d: any) => {
                if (!event.active) simulation.alphaTarget(0);
                d.fx = null;
                d.fy = null;
              }),
          );

        const text = graphGroup
          .append("g")
          .attr("class", "texts")
          .selectAll("text")
          .data(layoutNodes)
          .enter()
          .append("text")
          .attr("x", (d: any) => d.x + 15)
          .attr("y", (d: any) => d.y + 5)
          .text((d: any) => getNodeName(d.label))
          .style("font-size", "12px")
          .style("fill", theme.palette.text.primary);

        simulation.on("tick", () => {
          link
            .selectAll("line")
            .attr("x1", (d: any) => d.source.x)
            .attr("y1", (d: any) => d.source.y)
            .attr("x2", (d: any) => d.target.x)
            .attr("y2", (d: any) => d.target.y);

          node
            .selectAll("circle")
            .attr("cx", (d: any) => d.x)
            .attr("cy", (d: any) => d.y);

          node
            .selectAll("image")
            .attr("x", (d: any) => d.x - 10)
            .attr("y", (d: any) => d.y - 10);

          text.attr("x", (d: any) => d.x + 15).attr("y", (d: any) => d.y + 5);

          const nodesBBox = node.nodes().reduce(
            (bbox, node) => {
              const { x, y } = node.__data__;
              bbox.minX = Math.min(bbox.minX, x);
              bbox.maxX = Math.max(bbox.maxX, x);
              bbox.minY = Math.min(bbox.minY, y);
              bbox.maxY = Math.max(bbox.maxY, y);
              return bbox;
            },
            {
              minX: Infinity,
              maxX: -Infinity,
              minY: Infinity,
              maxY: -Infinity,
            },
          );

          const bboxWidth = nodesBBox.maxX - nodesBBox.minX + 100;
          const bboxHeight = nodesBBox.maxY - nodesBBox.minY + 100;

          svg.attr(
            "viewBox",
            `${nodesBBox.minX - 50} ${nodesBBox.minY - 50} ${bboxWidth} ${bboxHeight}`,
          );
        });

        return () => {
          svg.remove();
        };
      }
    };

    initializeNetwork();

    return () => {
      if (graphRef.current) {
        d3.select(graphRef.current).select("svg").remove();
      }
    };
  }, [nodeDetails, layout, theme]);

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          flex: "1 0 auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1>Pain Visualisation</h1>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px", // Add some space between the components
          }}
        >
          <div>
            <Clippy
              state={clippyState}
              randomActionTime={ClippyRandomActionTime}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <label>Search:</label>
            <SearchBox
              placeholder="Cable, Circuit or Node..."
              searchTerm={id}
              setSearchTerm={setId}
              handleSearch={handleSearch}
            />
          </div>
          {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <label>Layout:</label>
            <DropdownList
              options={layoutOptions}
              selectedOption={layout}
              setSelectedOption={setLayout}
              placeholder="Select Layout"
            />
          </div> */}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          <div>
            {selectedNode?.invId === "" && (
              <div>
                <h2>{selectedNode.nodeName}</h2>
                <Alert severity="warning">No data available</Alert>
              </div>
            )}
            {selectedNode && selectedNode.invId !== "" && (
              <div>
                <h2>{selectedNode.nodeName}</h2>
                <Equipment id={selectedNode.invId ?? ""} />
              </div>
            )}
          </div>
          <div>
            {selectedLink === "" && (
              <Alert severity="warning">No data available</Alert>
            )}
            {selectedLink && selectedLink !== "" && (
              <div>
                <h2>{selectedLink.label}</h2>
                {linkDetails.map((linkDetail) => (
                  <>
                    <Alert severity="warning">
                      Port numbers may be 1 lower in real life
                    </Alert>
                    <table>
                      <thead>
                        <tr>
                          <th>Node</th>
                          <th>Interface</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {linkDetail.local
                              ? getNodeName(linkDetail.local.node ?? "")
                              : "N/A"}
                          </td>
                          <td>
                            {linkDetail.local
                              ? `${linkDetail.local.card}/${linkDetail.local.port}`
                              : "N/A"}
                          </td>
                        </tr>
                        {linkDetail.adjacents?.map((adjacent, index) => (
                          <tr key={index}>
                            <td>{getNodeName(adjacent.node ?? "")}</td>
                            <td>{`${adjacent.card}/${adjacent.port}`}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                ))}
              </div>
            )}
          </div>
        </div>
        <div
          ref={graphRef}
          style={{
            flex: "1 1 auto",
            height: "calc(100% - 100px)",
            width: "100%",
            overflow: "auto",
          }}
        />
      </div>
    </div>
  );
};

export const PainAdjacency = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const inputRef = useRef<HTMLInputElement>(null); // Create a ref
  const [searchResult, setSearchResult] = useState<NodeAdjacencies | null>(
    null,
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleSearch = async (term: string) => {
    setLoading(true);
    setErrorMessage(null);
    try {
      setSearchResult(null); // set search result to null before search
      term = term
        .split(" ")
        .map((word) => word.toUpperCase())
        .join(" ");

      const adjacencies = await fetchPainAdjacencies(term, true);
      setSearchResult(adjacencies || null); // Provide a default value of null if response?.addressInfo is undefined
      setErrorMessage(null);
    } catch (error) {
      console.error(`Error: ${error} `);
      setErrorMessage(`Error: ${error} `);
    } finally {
      setLoading(false);
    }
  };

  const handleNodeClick = (node: string) => {
    setSearchTerm(node);
    handleSearch(node);
  };

  return (
    <div>
      <h1>Pain Adjacency</h1>
      <SearchBox
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleSearch={() => handleSearch(searchTerm)}
        inputRef={inputRef}
        placeholder="Enter TM node ID..."
      />
      {loading ? <CircularProgress /> : null}
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      {searchResult && searchResult.length > 0 && (
        <>
          <h2>Adjacencies for {searchTerm}</h2>
          <Alert severity="warning">
            Port numbers from Telemator may be 1 lower in real life.
          </Alert>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Port</TableCell>
                  <TableCell>Circuit ID</TableCell>
                  <TableCell>Circuit Type</TableCell>
                  <TableCell>Adjacent Node</TableCell>
                  <TableCell>Adjacent Port</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchResult.map((result, index) => (
                  <React.Fragment key={index}>
                    <TableRow>
                      <TableCell rowSpan={result.adjacents?.length}>
                        {result.local?.card}/{result.local?.port}
                      </TableCell>
                      <TableCell rowSpan={result.adjacents?.length}>
                        {result.local?.circuit?.id}
                      </TableCell>
                      <TableCell rowSpan={result.adjacents?.length}>
                        {result.local?.circuit?.type}
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={() => {
                            if (result.adjacents && result.adjacents[0]?.node) {
                              handleNodeClick(result.adjacents[0].node);
                            }
                          }}
                        >
                          {result.adjacents && result.adjacents[0]?.node}
                        </Button>
                      </TableCell>
                      <TableCell>
                        {result.adjacents
                          ? `${result.adjacents[0]?.card}/${result.adjacents[0]?.port}`
                          : null}
                      </TableCell>
                    </TableRow>
                    {result.adjacents?.slice(1).map((adjacent, adjIndex) => (
                      <TableRow key={adjIndex}>
                        <TableCell>
                          <Button
                            onClick={() => {
                              if (adjacent.node) {
                                handleNodeClick(adjacent.node);
                              }
                            }}
                          >
                            {adjacent.node}
                          </Button>
                        </TableCell>
                        <TableCell>
                          {adjacent.card}/{adjacent.port}
                        </TableCell>
                      </TableRow>
                    ))}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
};

export const Pain = () => {
  return <PainVisualisation />;
};

export default PainAdjacency;
