import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { OpenAPI } from "../gen/cfsInventoryClient/core/OpenAPI";
import { CfsInventoryServicesService } from "../gen/cfsInventoryClient/services.gen";
import {
  CfsInventoryServicesGetServiceByDisplayNameData,
  ServiceByDisplayNameResponse,
  CfsInventoryServicesGetServiceComponentsData,
  ServicePairs,
  Service,
  CfsInventoryServicesGetServiceByIdData,
  ServiceByIdResponse,
} from "../gen/cfsInventoryClient/types.gen";
import { addAuthorizationHeader, acquireAccessToken } from "../utils/auth";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Alert,
  Box,
  Paper,
  Grid,
} from "@mui/material";
import { inventoryConfig } from "../authConfig";
import SearchBox from "../ui-components/SearchBox";
import ServiceCard from "../ui-components/ServiceCard";
import ServiceTopology from "../ui-components/ServiceTopology";
import ContentCopy from "../ui-components/ContentCopy";
import { EquipmentSummaryWithPopover } from "../ui-components/Equipment";
import { ConfigureResponse } from "../gen/cfsInventoryClient/types.gen";
import { StatusIconConfigResponsesAggregated } from "../ui-components/ConfigResponseStatus";
import { LegacyService } from "../ui-components/LegacyServiceFinder";

//OpenAPI.TOKEN = acquireAccessToken;
OpenAPI.BASE = inventoryConfig.baseUrl;

// OpenAPI.interceptors.request.use(addAuthorizationHeader);
OpenAPI.interceptors.request.use((config) =>
  addAuthorizationHeader(config, inventoryConfig.scope),
);
export default function Services() {
  const { serviceName } = useParams<{ serviceName?: string }>();
  const [searchTerm, setSearchTerm] = useState("");
  const [input, setInput] = useState("");
  const inputRef = useRef<HTMLInputElement>(null); // Create a ref
  const [searchResult, setSearchResult] = useState<Service | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [servicePairs, setServicePairs] = useState<ServicePairs | null>(null);
  const [configResponses, setConfigResponses] = useState<
    Map<string, Array<ConfigureResponse>>
  >(new Map());

  const [customerLine, setCustomerLine] = useState<Service | null>(null);
  const [allServices, setAllServices] = useState<Array<Service>>([]);
  const [l2p2p, setL2P2P] = useState<Service | null>(null);
  const [internet, setInternet] = useState<Service | null>(null);
  const [spacing, setSpacing] = React.useState(2);
  const [displayServiceTopology, setDisplayServiceTopology] = useState(true);
  const renderedServiceTopology = useRef(false);
  const [isLegacyService, setIsLegacyService] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSpacing(Number((event.target as HTMLInputElement).value));
  };
  const navigate = useNavigate();
  const handleSearch = () => {
    setSearchTerm(input);
  };
  const addConfigResponse = (
    id: string,
    responses: Array<ConfigureResponse> | undefined,
  ) => {
    if (!responses) {
      return;
    }
    setConfigResponses((prevConfigResponses) => {
      const newConfigResponses = new Map(prevConfigResponses);
      newConfigResponses.set(id, responses);
      return newConfigResponses;
    });
  };

  useEffect(() => {
    setSearchTerm(serviceName || "");
  }, []);

  // Render service topology when allServices and servicePairs are loaded
  useEffect(() => {
    if (allServices || servicePairs) {
      setDisplayServiceTopology(true);
    }
  }, [allServices, servicePairs]);

  useEffect(() => {
    if (searchTerm) {
      const currentPath = window.location.pathname;
      let basePath = currentPath.substring(0, currentPath.lastIndexOf("/"));
      const currentSearch = currentPath.substring(
        currentPath.lastIndexOf("/") + 1,
      );
      if (currentSearch === "services") {
        basePath = currentPath;
      }
      if (currentSearch != searchTerm) {
        navigate(`${basePath}/${searchTerm}`);
      }
      getData();
      return;
    }
    console.error("searchTerm is empty, navigation aborted");
  }, [searchTerm]);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    if (searchResult) {
      const fetchData = async () => {
        const token = await acquireAccessToken();
        OpenAPI.TOKEN = token;

        const input: CfsInventoryServicesGetServiceComponentsData = {
          serviceId: searchResult.id,
        };
        const response =
          (await CfsInventoryServicesService.cfsInventoryServicesGetServiceComponents(
            input,
          )) as ServicePairs;
        if (response?.service_pairs !== undefined) {
          setServicePairs(response.service_pairs);
        } else {
          setServicePairs(null);
        }
        //setServicePairs(response?.service_pairs);
        // CUSTOMER_LINE --> L2_P2P --> INTERNET --> ROUTE
        // INTERNET --> ROUTE
        // INTERNET --> IP_SUBNET
        // ROUTE --> IP_SUBNET
        let pairs = response?.service_pairs;
        let newServices = [...allServices];

        if (pairs) {
          pairs.forEach((pair) => {
            if (pair.service_a.service_type === "CUSTOMER_LINE") {
              setCustomerLine(pair.service_a);
            }
            if (pair.service_a.service_type === "L2_P2P") {
              setL2P2P(pair.service_a);
            }
            if (pair.service_a.service_type === "INTERNET") {
              setInternet(pair.service_a);
            }
            const serviceAExists = newServices.some(
              (service) => service.id === pair.service_a.id,
            );
            const serviceBExists = newServices.some(
              (service) => service.id === pair.service_b.id,
            );

            if (!serviceAExists) {
              newServices.push(pair.service_a);
            }

            if (!serviceBExists && pair.service_a.id !== pair.service_b.id) {
              newServices.push(pair.service_b);
            }
          });
        } else {
          if (searchResult.service_type === "CUSTOMER_LINE") {
            setCustomerLine(searchResult);
          }
          if (searchResult.service_type === "L2_P2P") {
            setL2P2P(searchResult);
          }
          if (searchResult.service_type === "INTERNET") {
            setInternet(searchResult);
          }
          newServices.push(searchResult);
        }

        newServices.sort((a, b) => {
          if (
            a.service_type === "CUSTOMER_LINE" &&
            b.service_type !== "CUSTOMER_LINE"
          )
            return -1;
          if (
            b.service_type === "CUSTOMER_LINE" &&
            a.service_type !== "CUSTOMER_LINE"
          )
            return 1;
          if (a.service_type === "L2_P2P" && b.service_type !== "L2_P2P")
            return -1;
          if (b.service_type === "L2_P2P" && a.service_type !== "L2_P2P")
            return 1;
          if (a.service_type === "INTERNET" && b.service_type !== "INTERNET")
            return -1;
          if (b.service_type === "INTERNET" && a.service_type !== "INTERNET")
            return 1;
          return 0;
        });
        setAllServices(newServices);
      };
      fetchData();
    }
  }, [searchResult]);

  useEffect(() => {
    // Handle back button
    const handlePopState = () => {
      const currentPath = window.location.pathname;
      const currentSearch = currentPath.substring(
        currentPath.lastIndexOf("/") + 1,
      );
      setSearchTerm(currentSearch || "");
      getData();
    };
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const getData = async () => {
    setLoading(true);
    setErrorMessage(null);
    setCustomerLine(null);
    setL2P2P(null);
    setInternet(null);
    setAllServices([]);
    setServicePairs(null);
    setConfigResponses([]);
    try {
      setSearchResult(null); // set seach result to null before search
      setIsLegacyService(false);
      const inputValue = (searchTerm || "")
        .split(" ")
        .map((word) => word.toUpperCase())
        .join(" ");
      const uuidRegex =
        /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
      let isUUID = false;
      if (uuidRegex.test(inputValue)) {
        isUUID = true;
      }
      const newPatternRegex = /^[a-zA-Z]{2}[0-9]{2}-[0-9]{4}$/;
      let matchesPattern = false;
      if (newPatternRegex.test(inputValue)) {
        matchesPattern = true;
      }
      const legacyPatternRegex = /^[a-zA-Z]{1}[0-9]{3}[a-zA-Z]{1}-[0-9]{4}$/;
      if (legacyPatternRegex.test(inputValue)) {
        matchesPattern = true;
        setIsLegacyService(true);
        return;
      }
      if (!isUUID && !matchesPattern) {
        setErrorMessage("Invalid input, må være display name eller uuid");
        return;
      }

      const token = await acquireAccessToken();
      OpenAPI.TOKEN = token;

      const input: CfsInventoryServicesGetServiceByDisplayNameData = {
        displayName: inputValue,
      };
      if (isUUID) {
        const idInput: CfsInventoryServicesGetServiceByIdData = {
          serviceId: inputValue,
        };
        const response =
          (await CfsInventoryServicesService.cfsInventoryServicesGetServiceById(
            idInput,
          )) as ServiceByIdResponse;
        setSearchResult(response.service || null);
      } else {
        const response =
          (await CfsInventoryServicesService.cfsInventoryServicesGetServiceByDisplayName(
            input,
          )) as ServiceByDisplayNameResponse;
        setSearchResult(response.service || null);
      }
      setErrorMessage(null);
    } catch (error) {
      console.error(`Error: ${error} `);
      setErrorMessage(`Error: ${error} `);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ width: "75%" }}>
      <h1>Tjenestesøk</h1>
      <SearchBox
        searchTerm={input}
        setSearchTerm={setInput}
        handleSearch={handleSearch}
        inputRef={inputRef}
      />
      {loading ? <CircularProgress /> : null}
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      {isLegacyService && <LegacyService legacyServiceId={serviceName} />}
      {searchResult && (
        <Box>
          {displayServiceTopology && (
            <ServiceTopology
              services={allServices}
              servicePairs={servicePairs}
              id={searchResult.id}
            />
          )}
          <h2>
            Service details{" "}
            <StatusIconConfigResponsesAggregated
              configResponsesMap={configResponses}
            />
          </h2>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Display name:</TableCell>
                  <TableCell>{searchResult.display_name}</TableCell>
                  <TableCell />
                  <TableCell>
                    <ContentCopy value={searchResult.display_name ?? ""} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Service Id:</TableCell>
                  <TableCell>{searchResult.id}</TableCell>
                  <TableCell />
                  <TableCell>
                    <ContentCopy value={searchResult.id} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Service Type:</TableCell>
                  <TableCell>{searchResult.service_type}</TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
                <TableRow>
                  <TableCell>External Refrence:</TableCell>
                  <TableCell>
                    {searchResult.reservation_ext_reference}
                  </TableCell>
                  <TableCell />
                  <TableCell>
                    <ContentCopy
                      value={searchResult.reservation_ext_reference}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    rowSpan={searchResult.resource_equipment_ports?.length + 1}
                  >
                    EquipmentPorts:
                  </TableCell>
                </TableRow>
                {searchResult.resource_equipment_ports?.map((port, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <EquipmentSummaryWithPopover
                        name={
                          port.resource_equipment?.host_name ??
                          "Unknown hostname"
                        }
                        id={port.resource_equipment?.id ?? ""}
                      />
                    </TableCell>
                    <TableCell>{port.name}</TableCell>
                    <TableCell>
                      <ContentCopy value={port.name} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      {searchResult && <h2>Service components</h2>}

      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {allServices &&
            allServices.map((pair, index) => (
              <Grid key={index} item xs={6} md={4}>
                <ServiceCard
                  id={pair.id}
                  service_type={pair.service_type}
                  display_name={pair.display_name}
                  changeSearchTerm={setSearchTerm}
                  statusCallback={addConfigResponse}
                />
              </Grid>
            ))}
        </Grid>
      </Box>
      {servicePairs && (
        <Box>
          <h2>Service pairs</h2>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Service A</TableCell>
                  <TableCell>Type A</TableCell>
                  <TableCell>Service B</TableCell>
                  <TableCell>Type B</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {servicePairs.map((pair, index) => (
                  <TableRow key={index}>
                    <TableCell>{pair.service_a.display_name}</TableCell>
                    <TableCell>{pair.service_a.service_type}</TableCell>
                    <TableCell>{pair.service_b.display_name}</TableCell>
                    <TableCell>{pair.service_b.service_type}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
}
