import React, { useState, useEffect } from "react";

export type ClippyState =
  | ""
  | "lookingDownLeft"
  | "lookingDownRight"
  | "loookingDown"
  | "scratch"
  | "looking"
  | "explode"
  | "success"
  | "warning"
  | "relax"
  | "pointRight"
  | "pointDown"
  | "pointUp"
  | "sleepy"
  | "goofy"
  | "knocking"
  | "bikeExit"
  | "bikeEnter"
  | "reading"
  | "music"
  | "atom"
  | "tornado"
  | "packing";

export interface ClippyProps {
  state: ClippyState;
  timer?: number;
  randomActionTime?: number;
}

const actionLength = {
  lookingDownLeft: 1800 * 2,
  lookingDownRight: 1800 * 2,
  loookingDown: 1800 * 2,
  looking: 8100,
  success: 4400,
  warning: 4800,
  pointRight: 3000 * 2,
  pointDown: 2200 * 2,
  pointUp: 2800 * 2,
  scratch: 3300 * 2,
  relax: 13600,
  sleepy: 7500,
  goofy: 8400,
  knocking: 2600 * 2,
  bikeExit: 4300,
  bikeEnter: 3500,
  reading: 6600,
  music: 2400 * 2,
  packing: 5500,
  atom: 4500,
  tornado: 5000,
};

const randomActions = [
  "scratch",
  "lookingDownLeft",
  "lookingDownRight",
  "loookingDown",
  "relax",
  "sleepy",
  "goofy",
  "knocking",
  "bikeExit",
  "bikeEnter",
  "reading",
  "music",
  "atom",
  "tornado",
] as ClippyState[];

export const explodeTimer = 400;
export const defaultTimer = 4000;
export const defaultRandomActionTime = 60000;
const minRandomActionTime = 20000;

const Clippy: React.FC<ClippyProps> = ({ state, timer, randomActionTime }) => {
  const [hide, setHide] = useState(false);
  const [currentState, setCurrentState] = useState(state); // Track the current state
  const [previousState, setPreviousState] =
    useState<ClippyProps["state"]>(state); // Initialize with the current state

  useEffect(() => {
    setPreviousState(currentState);
    setCurrentState(state);
  }, [state]);

  // Reset hide state when a new state is set
  useEffect(() => {
    setHide(false);
  }, [state]);

  useEffect(() => {
    if (timer) {
      const timeOut = setTimeout(() => {
        setHide(true);
      }, timer);
      return () => clearTimeout(timeOut);
    }
  }, [timer]);

  // Random action logic after randomActionTime expires
  useEffect(() => {
    if (randomActionTime) {
      if (randomActionTime < minRandomActionTime) {
        randomActionTime = minRandomActionTime;
      }
      // Wait for the randomActionTime to expire before performing a random action
      const randomActionTimeout = setTimeout(() => {
        // Save the current state before performing a random action
        setPreviousState(currentState);

        // Perform a random action
        const randomAction =
          randomActions[Math.floor(Math.random() * randomActions.length)];
        setCurrentState(randomAction);

        // After actionTimeout, revert to the previous state
        const revertTimeout = setTimeout(() => {
          setCurrentState(previousState); // Revert to the previous state
        }, actionLength[randomAction]);

        return () => clearTimeout(revertTimeout);
      }, randomActionTime);

      return () => clearTimeout(randomActionTimeout);
    }
  }, [randomActionTime, currentState, previousState]);

  if (timer && hide) {
    return null;
  }

  switch (currentState) {
    case "warning":
      return (
        <img
          src="https://media.tenor.com/ZwYGC1LMgHIAAAAi/clippy.gif"
          alt="Clippy warning..."
          width={122}
          height={93}
        />
      );
    case "scratch":
      return (
        <img
          src="https://media.tenor.com/APUoW9dDiPsAAAAi/clippy-head-scratch.gif"
          alt="Clippy scratching head..."
          width={122}
          height={93}
        />
      );
    case "looking":
      return (
        <img
          src="https://media.tenor.com/cceueTeJSjoAAAAj/clippy.gif"
          alt="Clippy looking..."
          width={122}
          height={93}
        />
      );
    case "success":
      return (
        <img
          src="https://media.tenor.com/oa56X7RLjokAAAAi/clippy.gif"
          alt="Clippy"
          width={122}
          height={93}
        />
      );
    case "explode":
      return (
        <div style={{ position: "relative", width: 122, height: 93 }}>
          <img
            src="https://media.tenor.com/cceueTeJSjoAAAAj/clippy.gif"
            alt="Loading..."
            width={122}
            height={93}
            style={{ position: "absolute", top: 0, left: 0 }}
          />
          <img
            src="https://i.gifer.com/origin/8b/8b1b3a0e7a2ff5d84a2248c70a68df19_w200.webp"
            alt="Loading..."
            width={122}
            height={93}
            style={{ position: "absolute", top: 0, left: 0 }}
          />
        </div>
      );
    case "relax":
      return (
        <img
          src="https://media.tenor.com/RFZS3i-LsR8AAAAj/clippy.gif"
          alt="Clippy"
          width={122}
          height={93}
        />
      );
    case "pointRight":
      return (
        <img
          src="https://media.tenor.com/mGB2f7T1iUQAAAAj/clippy.gif"
          alt="Clippy"
          width={122}
          height={93}
        />
      );
    case "pointDown":
      return (
        <img
          src="https://media.tenor.com/4HO0la4zISkAAAAi/clippy.gif"
          alt="Clippy"
          width={122}
          height={93}
        />
      );
    case "pointUp":
      return (
        <img
          src="https://media.tenor.com/guawZDKRgncAAAAi/clippy.gif"
          alt="Clippy"
          width={122}
          height={93}
        />
      );
    case "sleepy":
      return (
        <img
          src="https://media.tenor.com/uA5JZjh_ofsAAAAi/clippy.gif"
          alt="Clippy"
          width={122}
          height={93}
        />
      );
    case "goofy":
      return (
        <img
          src="https://media.tenor.com/oaGGTJ_Fmx8AAAAi/clippy.gif"
          alt="Clippy"
          width={122}
          height={93}
        />
      );
    case "knocking":
      return (
        <img
          src="https://media.tenor.com/Tmu1IbKTtosAAAAi/clippy.gif"
          alt="Clippy"
          width={122}
          height={93}
        />
      );
    case "bikeExit":
      return (
        <img
          src="https://media.tenor.com/KbPESUov9hcAAAAi/clippy.gif"
          alt="Clippy"
          width={122}
          height={93}
        />
      );
    case "bikeEnter":
      return (
        <img
          src="https://media.tenor.com/qsAPDbuCR3oAAAAi/clippy.gif"
          alt="Clippy"
          width={122}
          height={93}
        />
      );
    case "reading":
      return (
        <img
          src="https://media.tenor.com/63k8-8UipCwAAAAi/clippy.gif"
          alt="Clippy"
          width={122}
          height={93}
        />
      );
    case "lookingDownLeft":
      return (
        <img
          src="https://media.tenor.com/WbF039Su6TIAAAAj/clippy.gif"
          alt="Clippy"
          width={122}
          height={93}
        />
      );
    case "lookingDownRight":
      return (
        <img
          src="https://media.tenor.com/KIlLdyx83JQAAAAi/clippy.gif"
          alt="Clippy"
          width={122}
          height={93}
        />
      );
    case "loookingDown":
      return (
        <img
          src="https://media.tenor.com/c48E2xWwC4gAAAAi/clippy.gif"
          alt="Clippy"
          width={122}
          height={93}
        />
      );
    case "music":
      return (
        <img
          src="https://media.tenor.com/x10tu93AnNQAAAAi/clippy.gif"
          alt="Clippy"
          width={122}
          height={93}
        />
      );
    case "packing":
      return (
        <img
          src="https://media.tenor.com/JqkNT68NBxgAAAAi/clippy.gif"
          alt="Clippy"
          width={122}
          height={93}
        />
      );
    case "atom":
      return (
        <img
          src="https://media.tenor.com/T808PcBj4tgAAAAi/clippy.gif"
          alt="Clippy"
          width={122}
          height={93}
        />
      );
    case "tornado":
      return (
        <img
          src="https://media.tenor.com/7LcaZrg95ZIAAAAi/clippy.gif"
          alt="Clippy"
          width={122}
          height={93}
        />
      );
    default:
      return (
        <img
          src="https://media.tenor.com/WbF039Su6TIAAAAj/clippy.gif"
          alt="Clippy"
          width={122}
          height={93}
        />
      );
  }
};

export default Clippy;
