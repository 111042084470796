import { acquireAccessToken } from "../../utils/auth";
import { OpenAPI } from "../../gen/cfsInventoryClient/core/OpenAPI";
import {
  CfsInventoryResourceEquipmentsGetMgmtEquipmentByResourceEquipmentData,
  CfsInventoryResourceEquipmentsGetResourceEquipmentByIdData,
  GetResourceEquipmentByIdResponse,
  GetMgmtEquipmentByResourceEquipmentResponse,
  ResourceEquipment,
  MgmtEquipment,
} from "../../gen/cfsInventoryClient/types.gen";
import { CfsInventoryResourceEquipmentsService } from "../../gen/cfsInventoryClient/services.gen";


export const fetchResourceEquipmentById = async (id: string): Promise<ResourceEquipment | undefined> => {
    try {
      const token = await acquireAccessToken();
      OpenAPI.TOKEN = token;
      const request: CfsInventoryResourceEquipmentsGetResourceEquipmentByIdData =
        {
          id: id,
        };
      const response =
        (await CfsInventoryResourceEquipmentsService.cfsInventoryResourceEquipmentsGetResourceEquipmentById(
          request,
        )) as GetResourceEquipmentByIdResponse;
      return response?.resource_equipment;
    } catch (error) {
      console.error(error);
    } 
    return undefined;
  };

  export const fetchManagementEquipmentByResourceEquipmentId = async (id: string): Promise<MgmtEquipment | undefined> => {
    try {
      const token = await acquireAccessToken();
      OpenAPI.TOKEN = token;
      const mgmtEquipmentRequest: CfsInventoryResourceEquipmentsGetMgmtEquipmentByResourceEquipmentData =
        {
          resourceEquipmentId: id,
        };
      const mgmtEquipmentResponse =
        (await CfsInventoryResourceEquipmentsService.cfsInventoryResourceEquipmentsGetMgmtEquipmentByResourceEquipment(
          mgmtEquipmentRequest,
        )) as GetMgmtEquipmentByResourceEquipmentResponse;
      return mgmtEquipmentResponse?.mgmt_equipment;
    } catch (error) {
      console.error(error);
    }
    return undefined;
  };