import { SearchService } from "../../gen/cfsInventoryClient/services.gen";
import { CfExternalSystem, CfIdentifyResponse } from "../../gen/cfsInventoryClient/types.gen";
import { configureOpenAPI } from "../../utils/openApi";
import { OpenAPI } from "../../gen/cfsInventoryClient/core/OpenAPI";


export type ResExtIdsByExtId = {
    externalId: string;
    externalIds: CfExternalSystem[];
  };

const isIdentifyResponse = (response: any): response is CfIdentifyResponse => {
    return response?.external_systems !== undefined;
  };
  
 export const fetchResourceEquipmentExternalIdsByExternalId = async (
    externalId: string,
  ): Promise<CfExternalSystem[] | undefined> => {
    try {
      await configureOpenAPI(OpenAPI);
      const response = await SearchService.searchIdentify({
        externalId,
      });
      if (isIdentifyResponse(response)) {
          return response.external_systems;
      } else {
        console.error("Response is not a valid IdentifyResponse:", response);
        return undefined;
      }
    } catch (error) {
      console.error("Error fetching resource equipment:", error);
      return undefined;
    }
  };

  // returns the first matching external id
export const fetchResourceEquipmentExternalIdsByExternalIds = async (
    externalIds: string[],
    ): Promise<CfExternalSystem[]> => {
    for (const id of externalIds) {
        const ids = await fetchResourceEquipmentExternalIdsByExternalId(id);
        if (ids) {
            return ids;
        }
    }
    return [];
};

// returns the first matching external id
export const fetchResourceEquipmentIdByExternalId = async (
    externalId: string,
    alternateIds?: string[],
  ): Promise<string | undefined> => {
    const extIds = [externalId, ...(alternateIds || [])];
    for (const id of extIds) {
        const ids = await fetchResourceEquipmentExternalIdsByExternalId(id);
        if (ids) {
        const resourceId = ids.find(
          (system) => system.system_name === "INVENTORY",
        );
        if (resourceId) {
          return resourceId.external_id;
        }
      }
    }
    return undefined;
  };

    // returns the first matching external id
  export const fetchTelematorIdByExternalId = async (
    externalId: string,
    alternateIds?: string[],
  ): Promise<string | undefined> => {
      const extIds = [externalId, ...(alternateIds || [])];
      for (const id of extIds) {
          const ids = await fetchResourceEquipmentExternalIdsByExternalId(id);
          if (ids) {
          const resourceId = ids.find(
            (system) => system.system_name === "TELEMATOR",
          );
          if (resourceId) {
            return resourceId.external_id;
          }
        }
      }
      return undefined;
  };