import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Typography from "@mui/material/Typography";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

export function Home() {
  return (
    <>
      <ButtonGroup orientation="horizontal">
        <Button
          component={RouterLink}
          to="/profile"
          variant="contained"
          color="primary"
        >
          Min profil
        </Button>
        <Button
          component={RouterLink}
          to="/points"
          variant="contained"
          color="primary"
        >
          Points
        </Button>
      </ButtonGroup>
    </>
  );
}
