import React, { useEffect, useState } from "react";
import { ServiceTopologySimple } from "./ServiceTopology";
import { getLegacy } from "../gen/bandwidthClient/services.gen";
import {
  Service,
  ServiceParameter,
  ServicePair,
  CfExternalSystem,
} from "../gen/cfsInventoryClient/types.gen";
import { configureOpenAPI } from "../utils/openApi";
import { OpenAPI as BandwidthOpenAPI } from "../gen/bandwidthClient/core/OpenAPI";
import {
  fetchResourceEquipmentIdByExternalId,
  fetchResourceEquipmentExternalIdsByExternalIds,
} from "../utils/inventory/identify";

import { v4 as uuidv4 } from "uuid";
import {
  LegacyResponse,
  LegacyInterfaceResult,
} from "../gen/bandwidthClient/types.gen";
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { toast } from "react-toastify";
import PainAdjacency from "../pages/Pain";
import fetchPainAdjacencies from "../utils/pain/painAdjacencies";
import Clippy, { defaultTimer, explodeTimer } from "./clippy";
import { LegacyOperationalStatus } from "./OperationalStatus";

type ServiceType =
  | "OTHER_SERVICE"
  | "CUSTOMER_LINE"
  | "INTERNET"
  | "CPE"
  | "L2_P2P"
  | "IP_SUBNET"
  | "VLAN"
  | "IP_VPN"
  | "VRF_ALLOCATION"
  | "ROUTE";

type ServiceChain = {
  services: Service[];
  servicePairs: ServicePair[];
  serviceParameters?: ServiceParameters;
  warning?: boolean;
};

type ServiceWithParameters = {
  service: Service;
  serviceParameters?: ServiceParameters;
  warning?: boolean;
};

type ServiceParameters = {
  circuitId: string;
  customerId: string;
  speed: string;
  serviceType: string;
  description: string;
};

type extIdMap = {
  [key: string]: CfExternalSystem[];
};

export const LegacyService = ({ legacyServiceId }) => {
  const [loading, setLoading] = useState(true);
  const [warning, setWarning] = useState(false);
  const [serviceType, setServiceType] = useState<ServiceType>();
  const [legacyServiceResponse, setLegacyServiceResponse] =
    useState<LegacyResponse>();
  const [services, setServices] = useState<Service[]>([]);
  const [servicePairs, setServicePairs] = useState<ServicePair[]>([]);
  const [serviceParameters, setServiceParameters] =
    useState<ServiceParameters>();

  useEffect(() => {
    const fetchLegacyServiceInterfaces = async (legacyServiceId: string) => {
      setWarning(false);
      setLoading(true);
      setServices([]);
      setServicePairs([]);
      setServiceParameters(undefined);
      try {
        const resp = await getLegacyServiceInterfaces({ legacyServiceId });
        setLegacyServiceResponse(resp);
        if (resp?.d?.results === undefined) {
          toast.error("No legacy service interfaces found when querying PM!");
          setLoading(false);
          return;
        }
        const detectedServiceType = getServiceType(resp.d.results);
        setServiceType(detectedServiceType);
        let serviceChain: ServiceChain = { services: [], servicePairs: [] };
        switch (detectedServiceType) {
          case "INTERNET": // fallthrough
          case "IP_VPN":
            serviceChain = await assembleL3ServiceChain(
              legacyServiceId,
              detectedServiceType,
              resp.d.results,
            );
            break;
          case "L2_P2P":
            serviceChain = await assembleL2P2PServiceChain(
              legacyServiceId,
              detectedServiceType,
              resp.d.results,
            );
            break;
          default:
            break;
        }
        if (serviceChain.warning) {
          setWarning(true);
        }
        setServiceParameters(serviceChain.serviceParameters);
        setServices(serviceChain.services);
        setServicePairs(serviceChain.servicePairs);
      } catch (error) {
        toast.error("Error fetching legacy service interfaces:", error);
        setWarning(true);
      }
      setLoading(false);
    };
    fetchLegacyServiceInterfaces(legacyServiceId);
  }, [legacyServiceId]);

  if (loading) {
    return (
      <div>
        <h2>Searching for legacy circuit: {legacyServiceId}</h2>
        <Clippy state="looking" />
      </div>
    );
  }
  // if services or servicepairs are empty, return some helpful data
  if (services.length === 0 || servicePairs.length === 0) {
    return (
      <div>
        <h2>Could not generate service graph for {legacyServiceId}</h2>
        <Clippy state="explode" timer={explodeTimer} />

        {legacyServiceResponse
          ? displayLegacyServiceResponse(legacyServiceResponse, serviceType)
          : null}
      </div>
    );
  }
  return (
    <div>
      <h1>Legacy Service</h1>
      {warning ? (
        <Clippy state="warning" />
      ) : (
        <Clippy state="success" timer={defaultTimer} />
      )}
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Service</TableCell>
              <TableCell>Service Type</TableCell>
              <TableCell>Customer</TableCell>
              <TableCell>Speed</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{legacyServiceId}</TableCell>
              <TableCell>{serviceParameters?.serviceType}</TableCell>
              <TableCell>{serviceParameters?.customerId}</TableCell>
              <TableCell>{serviceParameters?.speed}</TableCell>
              <TableCell>{serviceParameters?.description}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <p />
      <ServiceTopologySimple
        services={services}
        servicePairs={servicePairs}
        legacy={true}
      />
      {warning && legacyServiceResponse
        ? displayLegacyServiceResponse(legacyServiceResponse, serviceType)
        : null}
    </div>
  );
};

const assembleL3ServiceChain = async (
  legacyServiceId: string,
  serviceType: ServiceType,
  legacyInterfaces: LegacyInterfaceResult[],
): Promise<ServiceChain> => {
  const services: Service[] = [];
  const servicePairs: ServicePair[] = [];
  let warning = false;

  const l3Service = await generateService({
    serviceId: legacyServiceId,
    serviceType,
    results: legacyInterfaces,
  });
  const pwService = await generateService({
    serviceId: legacyServiceId,
    serviceType: "L2_P2P",
    results: legacyInterfaces,
  });
  const klService = await generateService({
    serviceId: legacyServiceId,
    serviceType: "CUSTOMER_LINE",
    results: legacyInterfaces,
  });
  services.push(klService.service, pwService.service, l3Service.service);
  servicePairs.push({
    service_a: klService.service,
    service_b: pwService.service,
  });
  servicePairs.push({
    service_a: pwService.service,
    service_b: l3Service.service,
  });
  if (l3Service.warning || pwService.warning || klService.warning) {
    warning = true;
  }
  return {
    services,
    servicePairs,
    serviceParameters: l3Service.serviceParameters,
    warning,
  };
};

const getNeighborInterfaces = async (
  service: ServiceWithParameters,
  interfaces: LegacyInterfaceResult[],
  extIds: extIdMap,
): Promise<{
  neighborInterfaces: LegacyInterfaceResult[];
  warning: boolean;
}> => {
  // find adjacent node to the service using pain adjacency and the telemator exteral id
  let neighbor = "";
  let warning = false;
  const localHostname =
    service.service.resource_equipment_ports?.[0]?.resource_equipment
      ?.host_name || "";
  const localTmId =
    extIds[localHostname]?.find((id) => id.system_name === "TELEMATOR")
      ?.external_id || "";
  if (!localTmId) {
    toast.error(`Could not find Telemator external ID for ${localHostname}`);
    console.error("Could not find Telemator external ID for", localHostname);
    warning = true;
  }
  const adjacencies = await fetchPainAdjacencies(localTmId, true);
  if (!adjacencies) {
    toast.error(`Could not find adjacencies for ${localTmId}`);
    console.error("Could not find adjacencies for", localTmId);
    warning = true;
  }
  for (const adj of adjacencies) {
    if (!adj.adjacents) {
      continue;
    }
    for (const node of adj.adjacents) {
      if (!node.node) {
        continue;
      }
      for (const key in extIds) {
        for (const extId of extIds[key]) {
          if (node.node === extId.external_id) {
            neighbor = key;
            break;
          }
        }
      }
    }
  }
  if (!neighbor) {
    toast.error(`Could not find upstream neighbor for ${localTmId}`);
    console.error("Could not find upstream neighbor for", localTmId);
    warning = true;
  }
  // find legacyInterfaces for the neighbor
  const neighborInterfaces = interfaces.filter(
    (int) => int.device?.SysName?.split(".")[0] === neighbor,
  );
  return { neighborInterfaces, warning };
};

// Find all the intermediary AGGs and create services for them until we reach the PE and create a service for that
const getIntermediaryServices = async (
  sourceService: ServiceWithParameters,
  legacyInterfaces: LegacyInterfaceResult[],
  extIds: extIdMap,
  legacyServiceId: string,
  serviceType: ServiceType,
): Promise<{ services: ServiceWithParameters[]; warning: boolean }> => {
  const services = [] as ServiceWithParameters[];
  let warning = false;
  const { neighborInterfaces, warning: neighborWarning } =
    await getNeighborInterfaces(sourceService, legacyInterfaces, extIds);
  warning = neighborWarning;

  const l2Service = await generateService({
    serviceId: legacyServiceId,
    serviceType,
    results: neighborInterfaces,
    isPE: isPe(neighborInterfaces[0]),
  });
  services.push(l2Service);
  if (isPe(neighborInterfaces[0])) {
    return { services, warning };
  }
  services.push(
    ...(
      await getIntermediaryServices(
        l2Service,
        legacyInterfaces,
        extIds,
        legacyServiceId,
        serviceType,
      )
    ).services,
  );
  for (const service of services) {
    if (service.warning) {
      warning = true;
    }
  }
  return { services, warning };
};

const assembleL2P2PServiceChain = async (
  legacyServiceId: string,
  serviceType: ServiceType,
  legacyInterfaces: LegacyInterfaceResult[],
): Promise<ServiceChain> => {
  const services: Service[] = [];
  const servicePairs: ServicePair[] = [];
  const usedNodes: string[] = [];
  let warning = true; // set warning to true until finished to see additional data

  const filterInterfaces = (
    interfaces: LegacyInterfaceResult[],
    interfaceName: string,
  ) => {
    return interfaces.filter((result) => result.Description !== interfaceName);
  };

  const extIds = {} as extIdMap;
  for (const int of legacyInterfaces) {
    const hostname = int.device?.SysName?.split(".")[0] ?? ""; // remove domain from hostname
    const ipAddress = int.device?.PrimaryIPAddress ?? "";
    if (!hostname || !ipAddress) {
      console.error("Could not find hostname or IP address for", int);
      continue;
    }
    const ids = await fetchResourceEquipmentExternalIdsByExternalIds([
      hostname,
      ipAddress,
    ]);
    if (!ids) {
      console.error("Could not find external IDs for", hostname, ipAddress);
      continue;
    }
    extIds[hostname] = ids;
  }

  const getSideL2Sservice = async (): Promise<ServiceWithParameters[]> => {
    const klService = await generateService({
      serviceId: legacyServiceId,
      serviceType: "CUSTOMER_LINE",
      results: legacyInterfaces,
      isPE: true,
      usedNodes,
    });
    // Remove the interface used for the KL service from the list of interfaces and add to used nodes
    if (
      klService.service.resource_equipment_ports &&
      klService.service.resource_equipment_ports[0]?.name
    ) {
      legacyInterfaces = filterInterfaces(
        legacyInterfaces,
        klService.service?.resource_equipment_ports[0].name,
      );
      if (
        klService.service.resource_equipment_ports[0].resource_equipment
          ?.host_name
      ) {
        usedNodes.push(
          klService.service.resource_equipment_ports[0].resource_equipment
            .host_name,
        );
      }
    }
    // check if klService is a PE, if so it has no intermediary services
    if (
      klService.service?.resource_equipment_ports?.[0]?.resource_equipment
        ?.host_name
    ) {
      if (
        klService.service.resource_equipment_ports[0]?.resource_equipment?.host_name.includes(
          "PE",
        ) ||
        klService.service.resource_equipment_ports[0]?.resource_equipment?.host_name.includes(
          "SE",
        )
      ) {
        return [klService];
      }
    }

    const { services: klIntermediaryService, warning: intermediaryWarning } =
      await getIntermediaryServices(
        klService,
        legacyInterfaces,
        extIds,
        legacyServiceId,
        serviceType,
      );
    if (intermediaryWarning) {
      warning = true;
    }
    for (const service of klIntermediaryService) {
      // Remove the interface used for the intermediary service from the list of interfaces and add to used nodes
      if (
        service.service.resource_equipment_ports &&
        service.service.resource_equipment_ports[0]?.name
      ) {
        legacyInterfaces = filterInterfaces(
          legacyInterfaces,
          service.service?.resource_equipment_ports[0].name,
        );
        if (
          service.service.resource_equipment_ports[0].resource_equipment
            ?.host_name
        ) {
          usedNodes.push(
            service.service.resource_equipment_ports[0].resource_equipment
              .host_name,
          );
        }
      }
    }

    return [klService, ...klIntermediaryService] as ServiceWithParameters[];
  };

  // Find side A of the L2 service
  const sideAservices = await getSideL2Sservice();
  const sideBservices = await getSideL2Sservice();

  services.push(
    ...sideAservices.map((service) => service.service),
    ...sideBservices.map((service) => service.service).reverse(),
  );

  // Create service pairs for side A
  for (let i = 0; i < sideAservices.length - 1; i++) {
    servicePairs.push({
      service_a: sideAservices[i].service,
      service_b: sideAservices[i + 1].service,
    });
  }
  // Create service pair between last service in side A and last service in side B
  servicePairs.push({
    service_a: sideAservices[sideAservices.length - 1].service,
    service_b: sideBservices[sideBservices.length - 1].service,
  });
  // Create service pairs for side B in reverse order
  for (let i = 0; i < sideBservices.length - 1; i++) {
    servicePairs.push({
      service_a: sideBservices[i + 1].service,
      service_b: sideBservices[i].service,
    });
  }
  // flip service a and b in the last pair
  servicePairs[servicePairs.length - 1] = {
    service_a: servicePairs[servicePairs.length - 1].service_b,
    service_b: servicePairs[servicePairs.length - 1].service_a,
  };

  for (const service of [...sideAservices, ...sideBservices]) {
    if (service.warning) {
      warning = true;
    }
  }
  return {
    services,
    servicePairs,
    serviceParameters:
      sideAservices[sideAservices.length - 1].serviceParameters,
    warning,
  };
};

const displayLegacyServiceResponse = (
  legacyServiceResponse: LegacyResponse,
  serviceType?: ServiceType,
) => {
  if (legacyServiceResponse?.d?.results === undefined) {
    return null;
  }
  return (
    <div>
      <h2>Warning results may not be trusted</h2>
      <p>
        Found {legacyServiceResponse.d.results.length} Legacy Service Interfaces
        for presumed {serviceType} service
      </p>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>Device</TableCell>
              <TableCell>IP</TableCell>
              <TableCell>Interface</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
            {legacyServiceResponse.d.results.map((result, index) => (
              <TableRow key={index}>
                <TableCell>
                  <LegacyOperationalStatus
                    interfaceName={result.Description ?? ""}
                    mgmtIp={result.device?.PrimaryIPAddress ?? ""}
                  />
                </TableCell>
                <TableCell>{result.device?.SysName}</TableCell>
                <TableCell>{result.device?.PrimaryIPAddress}</TableCell>
                <TableCell>{result.Description}</TableCell>
                <TableCell>{result.Alias}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const getLegacyServiceInterfaces = async ({
  legacyServiceId,
}: {
  legacyServiceId: string;
}): Promise<LegacyResponse> => {
  try {
    await configureOpenAPI(BandwidthOpenAPI); // Ensure OpenAPI is configured before making the request
    const response = await getLegacy({
      extReference: legacyServiceId,
    });
    return response;
  } catch (error) {
    console.error(`Could not fetch overseer data!`);
    return {};
  }
};

const isPe = (device: LegacyInterfaceResult): boolean => {
  if (device.device) {
    if (
      device.device.SysName?.includes("-PE") ||
      device.device.SysName?.includes("-SE")
    ) {
      return true;
    }
    if (device.device.PrimaryIPAddress) {
      const ip = device.device.PrimaryIPAddress;
      return ip.startsWith("10.80") || ip.startsWith("10.81");
    }
  }
  return false;
};

const parseLegacyDescriptorFromServiceEdgeService = (
  results: LegacyInterfaceResult[],
): ServiceParameters | undefined => {
  const serviceEdgeResult = results.find((result) => {
    return isPe(result);
  });
  if (serviceEdgeResult === undefined) {
    return undefined;
  }
  const descriptionArray = serviceEdgeResult.Alias?.split(":");
  if (descriptionArray?.length === 5) {
    const [circuitId, customerId, speed, serviceType, description] =
      descriptionArray;
    return {
      circuitId,
      customerId,
      speed,
      serviceType,
      description,
    };
  }
  return undefined;
};

const generateService = async ({
  serviceId,
  serviceType,
  results,
  isPE,
  usedNodes,
}: {
  serviceId: string;
  serviceType: ServiceType;
  results: LegacyInterfaceResult[];
  isPE?: boolean;
  usedNodes?: string[];
}): Promise<ServiceWithParameters> => {
  switch (serviceType) {
    case "INTERNET":
    case "IP_VPN":
      return generateL3Service({ serviceId, serviceType, results });
    case "L2_P2P":
      return generateL2P2PService({ serviceId, serviceType, results, isPE });
    case "CUSTOMER_LINE":
      return generateCustomerLineService({
        serviceId,
        serviceType,
        results,
        isPE,
        usedNodes,
      });
    default:
      return generateOtherService({ serviceId, serviceType, results });
  }
};

const getResourceEquipmentIdFromNameOrIp = async (
  name: string | undefined,
  ip: string | undefined,
): Promise<string> => {
  if (name) {
    const id = await fetchResourceEquipmentIdByExternalId(name);
    if (id) {
      return id;
    }
    if (ip) {
      const id = await fetchResourceEquipmentIdByExternalId(ip);
      if (id) {
        return id;
      }
    }
  }
  return uuidv4();
};

const deduplicateServiceEdges = (
  serviceEdges: LegacyInterfaceResult[],
): LegacyInterfaceResult[] => {
  return serviceEdges.filter(
    (result, index, self) =>
      index ===
      self.findIndex(
        (t) =>
          t.device?.SysName === result.device?.SysName &&
          t.Description === result.Description,
      ),
  );
};

const filterServiceEdges = (results: LegacyInterfaceResult[]) => {
  const serviceEdgeResults = results.filter((result) => {
    return isPe(result);
  });
  const uniqueServiceEdges = deduplicateServiceEdges(serviceEdgeResults);
  return uniqueServiceEdges;
};

const generateL3Service = async ({
  serviceId,
  serviceType,
  results,
}: {
  serviceId: string;
  serviceType: ServiceType;
  results: LegacyInterfaceResult[];
}): Promise<ServiceWithParameters> => {
  let warning = false;
  const serviceEdges = filterServiceEdges(results);
  if (serviceEdges.length !== 1) {
    toast.error(
      `Found ${serviceEdges.length} possible service edge interfaces for L3 service, expected 1`,
    );
    warning = true;
  }
  const serviceEdgeResult = serviceEdges[0];
  if (serviceEdgeResult === undefined) {
    return { service: getBlankService(serviceId, serviceType), warning };
  }
  const serviceParameters =
    parseLegacyDescriptorFromServiceEdgeService(results);
  let spIn = "Unknown";
  let spOut = "Unknown";
  let spPrefix = "Unknown";
  let vrf = "Unknown";
  if (serviceType === "INTERNET") {
    spPrefix = "INTERNET";
    vrf = "ISP";
  } else if (serviceType === "IP_VPN") {
    spPrefix = "BUSINESS";
    vrf = "unknown_customer_vrf";
  }
  const hostname = serviceEdgeResult?.device?.SysName?.split(".")[0]; // remove domain from hostname

  if (serviceParameters) {
    if (serviceParameters.speed) {
      spIn = `${spPrefix}-${serviceParameters?.speed}-US`;
      spOut = `${spPrefix}-${serviceParameters?.speed}-DS`;
    }
  }

  const reid = await getResourceEquipmentIdFromNameOrIp(
    hostname,
    serviceEdgeResult?.device?.PrimaryIPAddress,
  );

  const service: Service = {
    id: uuidv4(),
    display_name: serviceId,
    service_type: serviceType,
    resource_equipment_ports: [
      {
        id: uuidv4(),
        name: serviceEdgeResult.Description || "",
        state: "ANY",
        display_name: serviceEdgeResult.Description || "",
        resource_equipment: {
          id: reid,
          host_name: hostname || "",
          state: "ANY",
          created_at: serviceEdgeResult.CreateTime || "",
          updated_at: serviceEdgeResult.CreateTime || "",
          access_model: "NONE",
          loopback_ip: serviceEdgeResult.device?.PrimaryIPAddress,
        },
        capability: ["OTHER"],
        created_at: serviceEdgeResult.CreateTime || "",
        updated_at: serviceEdgeResult.CreateTime || "",
      },
    ],
    state: "ANY",
    is_cfs: true,
    config_responses: [],
    created_at: serviceEdgeResult.CreateTime || "",
    updated_at: serviceEdgeResult.CreateTime || "",
    parameters: [
      {
        id: uuidv4(),
        name: "VRF",
        type: "SVC_POLICY",
        value: vrf,
        created_at: serviceEdgeResult.CreateTime,
        updated_at: serviceEdgeResult.CreateTime,
      },
      {
        id: uuidv4(),
        name: "IN",
        type: "SVC_POLICY",
        value: spIn,
        created_at: serviceEdgeResult.CreateTime,
        updated_at: serviceEdgeResult.CreateTime,
      },
      {
        id: uuidv4(),
        name: "OUT",
        type: "SVC_POLICY",
        value: spOut,
        created_at: serviceEdgeResult.CreateTime,
        updated_at: serviceEdgeResult.CreateTime,
      },
    ] as ServiceParameter[],
  };
  return { service, serviceParameters, warning };
};

const isAgg = (device: LegacyInterfaceResult): boolean => {
  if (device.device) {
    if (device.device.SysName?.includes("-AGG")) {
      return true;
    }
    if (device.device.PrimaryIPAddress) {
      const ip = device.device.PrimaryIPAddress;
      return ip.startsWith("10.191") || ip.startsWith("10.192");
    }
  }
  return false;
};

const generateL2P2PService = async ({
  serviceId,
  serviceType,
  results,
  isPE,
}: {
  serviceId: string;
  serviceType: ServiceType;
  results: LegacyInterfaceResult[];
  isPE?: boolean;
}): Promise<ServiceWithParameters> => {
  let warning = false;
  // Get the AGG connecting to the aCC
  const getServiceEdgeAgg = () => {
    // filter out services edges in the 10.191 and 10.192 range, as those are AGG devices
    const filteredResults = results.filter((result) => {
      if (result?.device?.PrimaryIPAddress) {
        const interfaceName = result.Description;
        // filter out bundle-ether interfaces as they are only used between AGGs and not towards ACCs
        if (interfaceName?.includes("Bundle-Ether")) {
          return false;
        }
        return isAgg(result);
      }
      return false;
    });
    const uniqueServiceEdges = deduplicateServiceEdges(filteredResults);
    // sort the results by hostname so we can pick the highest numbered suffix hostname (which is the AGG connecting to the )
    // example: somewhere-AGG-02 is preferred over somewhere-AGG-01, and somwhere-AGG04 is preferred over somewhere-AGG03 and somehere-different-AGG-02 is preferred over somewhere-different-AGG-01
    const sortedResults = uniqueServiceEdges.sort((a, b) => {
      const hostnameA = a.device?.SysName;
      const hostnameB = b.device?.SysName;
      const suffixA = hostnameA?.split("-").pop();
      const suffixB = hostnameB?.split("-").pop();
      if (suffixA === undefined) return 1;
      if (suffixB === undefined) return -1;
      return parseInt(suffixA) - parseInt(suffixB);
    });

    return sortedResults[0];
  };
  const getServiceEdgePe = () => {
    const serviceEdges = filterServiceEdges(results);
    if (serviceEdges.length !== 1) {
      toast.error(
        `Found ${serviceEdges.length} possible service edge interfaces for L2P2P service, expected 1`,
      );
      warning = true;
    }
    return serviceEdges[0];
  };
  const getServiceEdge = () => {
    if (isPE) {
      return getServiceEdgePe();
    }
    return getServiceEdgeAgg();
  };
  const serviceEdgeResult = getServiceEdge();

  if (serviceEdgeResult === undefined) {
    toast.error("Could not find service edge for L2P2P service");
    return { service: getBlankService(serviceId, serviceType), warning: true };
  }

  const serviceParameters =
    parseLegacyDescriptorFromServiceEdgeService(results);
  let spIn = "Unknown";
  let spOut = "Unknown";
  if (serviceParameters) {
    if (serviceParameters.speed) {
      spIn = `BUSINESS_2-${serviceParameters?.speed}-US`;
      spOut = `BUSINESS_2-${serviceParameters?.speed}-DS`;
    }
  }
  const hostname = serviceEdgeResult?.device?.SysName?.split(".")[0];
  const reid = await getResourceEquipmentIdFromNameOrIp(
    hostname,
    serviceEdgeResult?.device?.PrimaryIPAddress,
  );

  const service: Service = {
    id: uuidv4(),
    display_name: serviceId,
    service_type: serviceType,
    resource_equipment_ports: [
      {
        id: uuidv4(),
        name: serviceEdgeResult.Description || "",
        state: "ANY",
        display_name: serviceEdgeResult.Description || "",
        resource_equipment: {
          id: reid,
          host_name: hostname || "",
          state: "ANY",
          created_at: serviceEdgeResult.CreateTime || "",
          updated_at: serviceEdgeResult.CreateTime || "",
          access_model: "NONE",
          loopback_ip: serviceEdgeResult.device?.PrimaryIPAddress,
        },
        capability: ["OTHER"],
        created_at: serviceEdgeResult.CreateTime || "",
        updated_at: serviceEdgeResult.CreateTime || "",
      },
    ],
    state: "ANY",
    is_cfs: true,
    config_responses: [],
    created_at: serviceEdgeResult.CreateTime || "",
    updated_at: serviceEdgeResult.CreateTime || "",
    parameters: [
      {
        id: uuidv4(),
        name: "IN",
        type: "SVC_POLICY",
        value: spIn,
        created_at: serviceEdgeResult.CreateTime,
        updated_at: serviceEdgeResult.CreateTime,
      },
      {
        id: uuidv4(),
        name: "OUT",
        type: "SVC_POLICY",
        value: spOut,
        created_at: serviceEdgeResult.CreateTime,
        updated_at: serviceEdgeResult.CreateTime,
      },
    ] as ServiceParameter[],
  };
  return { service, serviceParameters, warning };
};

const isAcc = (device: LegacyInterfaceResult): boolean => {
  if (device.device) {
    if (
      device.device.SysName?.includes("-ACC") ||
      device.device.SysName?.includes("-GBE")
    ) {
      return true;
    }
    if (device.device.PrimaryIPAddress) {
      const ip = device.device.PrimaryIPAddress;
      return ip.startsWith("10.90") || ip.startsWith("10.91");
    }
  }
  return false;
};

const generateCustomerLineService = async ({
  serviceId,
  serviceType,
  results,
  isPE,
  usedNodes,
}: {
  serviceId: string;
  serviceType: ServiceType;
  results: LegacyInterfaceResult[];
  isPE?: boolean;
  usedNodes?: string[];
}): Promise<ServiceWithParameters> => {
  let warning = false;
  const getServiceedges = () => {
    if (isPE) {
      // sort the results, acc first, then agg then pe and se, sort used nodes last
      const sortTypes = results.sort((a, b) => {
        if (isAcc(a) && !isAcc(b)) return -1;
        if (!isAcc(a) && isAcc(b)) return 1;
        if (isAgg(a) && !isAgg(b)) return -1;
        if (!isAgg(a) && isAgg(b)) return 1;
        if (isPe(a) && !isPe(b)) return -1;
        if (!isPe(a) && isPe(b)) return 1;

        // Prefer non Bundle-Ether interfaces within each group
        if (
          a.Description?.includes("Bundle-Ether") &&
          !b.Description?.includes("Bundle-Ether")
        )
          return 1;
        if (
          !a.Description?.includes("Bundle-Ether") &&
          b.Description?.includes("Bundle-Ether")
        )
          return -1;

        return 0;
      });

      // remove nodes from sortedResult if they are in usedNodes
      if (usedNodes) {
        const sortedFirstResults = sortTypes.filter(
          (result) =>
            !usedNodes.includes(result.device?.SysName?.split(".")[0] || ""),
        );
        const sortLastResults = sortTypes.filter((result) =>
          usedNodes.includes(result.device?.SysName?.split(".")[0] || ""),
        );
        const sortResults = [...sortedFirstResults, ...sortLastResults];
        return sortResults;
      }
      return sortTypes;
    }
    return results.filter((result) => {
      if (result?.device?.PrimaryIPAddress) {
        const sysName = result.device.SysName;
        // filter out AGG, SE and PE devices
        if (
          sysName?.includes("-AGG") ||
          sysName?.includes("-SE") ||
          sysName?.includes("-PE")
        ) {
          return false;
        }
        return isAcc(result);
      }
      return false;
    });
  };
  const serviceEdgeResults = getServiceedges();
  const uniqueServiceEdges = deduplicateServiceEdges(serviceEdgeResults);

  if (uniqueServiceEdges.length !== 1) {
    toast.error(
      `Found ${serviceEdgeResults.length} possible Interfaces on ACCs for the Customer Line, showing the most likely one`,
    );
    warning = true;
    // select the serviceEdgeResult that has a description that does not start with Ten
    const filteredResults = serviceEdgeResults.filter(
      (result) => !result.Description?.startsWith("Ten"),
    );
    if (filteredResults.length && !isPE) {
      // prefer the non tenGig as a customer line if multiple are found
      serviceEdgeResults[0] = filteredResults[0];
    }
  }

  const serviceEdgeResult = serviceEdgeResults[0];
  if (serviceEdgeResult === undefined) {
    const service = getBlankService(serviceId, serviceType);
    return { service, serviceParameters: undefined, warning };
  }
  const serviceParameters =
    parseLegacyDescriptorFromServiceEdgeService(results);

  const hostname = serviceEdgeResult?.device?.SysName?.split(".")[0];
  const reid = await getResourceEquipmentIdFromNameOrIp(
    hostname,
    serviceEdgeResult?.device?.PrimaryIPAddress,
  );

  const service: Service = {
    id: uuidv4(),
    display_name: serviceId,
    service_type: serviceType,
    resource_equipment_ports: [
      {
        id: uuidv4(),
        name: serviceEdgeResult.Description || "",
        state: "ANY",
        display_name: serviceEdgeResult.Description || "",
        resource_equipment: {
          id: reid,
          host_name: hostname || "",
          state: "ANY",
          created_at: serviceEdgeResult.CreateTime || "",
          updated_at: serviceEdgeResult.CreateTime || "",
          access_model: "NONE",
          loopback_ip: serviceEdgeResult.device?.PrimaryIPAddress,
        },
        capability: ["OTHER"],
        created_at: serviceEdgeResult.CreateTime || "",
        updated_at: serviceEdgeResult.CreateTime || "",
      },
    ],
    state: "ANY",
    is_cfs: true,
    config_responses: [],
    created_at: serviceEdgeResult.CreateTime || "",
    updated_at: serviceEdgeResult.CreateTime || "",
    parameters: [] as ServiceParameter[],
  };
  return { service, serviceParameters, warning };
};

const generateOtherService = ({
  serviceId,
  serviceType,
  results,
}: {
  serviceId: string;
  serviceType: ServiceType;
  results: LegacyInterfaceResult[];
}): ServiceWithParameters => {
  const serviceParameters =
    parseLegacyDescriptorFromServiceEdgeService(results);
  toast.error(`Unsupported service type: ${serviceType}`);
  return {
    service: getBlankService(serviceId, serviceType),
    serviceParameters,
  };
};

const getBlankService = (
  circuitId: string,
  serviceType: ServiceType,
): Service => {
  return {
    id: uuidv4(),
    display_name: circuitId,
    service_type: serviceType,
    resource_equipment_ports: [
      {
        id: uuidv4(),
        name: "",
        state: "ANY",
        display_name: "Unkown interface",
        resource_equipment: {
          id: uuidv4(),
          host_name: "Unknown device",
          state: "ANY",
          created_at: "",
          updated_at: "",
          access_model: "NONE",
          loopback_ip: "",
        },
        capability: ["OTHER"],
        created_at: "",
        updated_at: "",
      },
    ],
    state: "ANY",
    is_cfs: true,
    config_responses: [],
    created_at: "",
    updated_at: "",
    parameters: [] as ServiceParameter[],
  };
};

const getServiceType = (results: LegacyInterfaceResult[]): ServiceType => {
  for (const result of results) {
    if (result.Alias) {
      const descriptionArray = result.Alias.split(":");
      if (descriptionArray.length > 3) {
        switch (descriptionArray[3]) {
          case "I":
            return "INTERNET";
          case "L":
            return "L2_P2P";
          case "V":
            return "IP_VPN";
        }
      }
    }
  }
  return "OTHER_SERVICE";
};
