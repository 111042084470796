import React, { useEffect, useState } from "react";
import "../styles/ServiceTopology.css";
import { acquireAccessToken } from "../utils/auth";
import { CfsInventoryServicesService } from "../gen/cfsInventoryClient/services.gen";
import { OpenAPI } from "../gen/cfsInventoryClient/core/OpenAPI";
import {
  CfsInventoryServicesGetServiceByIdData,
  CfsInventoryServicesGetServiceDescriptionData,
  CfGetServiceDescriptionResponse,
  CfServiceDescription,
  Service,
  ServicePairs,
  ServiceByIdResponse,
  ResourceEquipmentPort,
  ResourceEquipment,
  ServicePair,
} from "../gen/cfsInventoryClient/types.gen";
import { Box, IconButton } from "@mui/material";
import { OpenInFull, CloseFullscreenOutlined } from "@mui/icons-material";
import { toast } from "react-toastify";

import {
  OperationalStatusCustline,
  OperationalStatusInternetline,
  OperationalStatusIpVpnline,
  OperationalStatusL2line,
  LegacyOperationalStatus,
} from "./OperationalStatus";
import {
  ServiceRouterIcon,
  SwitchIcon,
  MPLSRouterIcon,
  CloudIcon,
  CpeHouseIcon,
  TextLinkIcon,
} from "./ServiceTopologyIcons";
import { EquipmentSummaryWithPopover } from "./Equipment";

interface ServiceTopologyCardProps {
  id: string;
  rowIndex: number;
  singleService?: boolean;
}

interface ServiceTopologyProps {
  services: Service[] | null;
  servicePairs: ServicePairs | null;
  id: string;
}

const maxServicesShown = 50;

const serviceTypeInGraph = (serviceType: string | undefined): boolean => {
  if (!serviceType) {
    return false;
  }
  return (
    serviceType === "CUSTOMER_LINE" ||
    serviceType === "IP_VPN" ||
    serviceType === "L2_P2P" ||
    serviceType === "INTERNET"
  );
};

const ServiceTopologyCard: React.FC<ServiceTopologyCardProps> = ({
  id,
  rowIndex,
  singleService,
}) => {
  let isMounted = true;
  const [serviceResult, setServiceResult] = useState<Service | null>(null);
  useEffect(() => {
    const fetchData = async () => {
      const token = await acquireAccessToken();
      OpenAPI.TOKEN = token;
      const input: CfsInventoryServicesGetServiceByIdData = {
        serviceId: id,
      };
      const response =
        (await CfsInventoryServicesService.cfsInventoryServicesGetServiceById(
          input,
        )) as ServiceByIdResponse;
      setServiceResult(response.service);
    };
    fetchData();
    return () => {
      isMounted = false;
    };
  }, [id]);

  return (
    <div>
      {serviceResult && (
        <Box display="flex" flexDirection="row" gap={0}>
          <ServiceTopologyGraph
            service={serviceResult}
            rowIndex={rowIndex}
            singleService={singleService}
          />
        </Box>
      )}
    </div>
  );
};

const LegacyServiceTopologyCard = ({
  service,
  rowIndex,
  singleService,
}: {
  service: Service;
  rowIndex: number;
  singleService: boolean;
}) => (
  <div>
    {service && (
      <Box display="flex" flexDirection="row" gap={0}>
        <ServiceTopologyGraph
          service={service}
          rowIndex={rowIndex}
          singleService={singleService}
          legacy={true}
        />
      </Box>
    )}
  </div>
);

const ServiceTopologyGraph = ({
  service,
  rowIndex,
  singleService,
  legacy,
}: {
  service: Service;
  rowIndex: number;
  singleService?: boolean;
  legacy?: boolean;
}) => {
  const graphStyle = {
    margin: 0,
    padding: 0,
    width: "100%",
  };

  switch (service.service_type) {
    case "INTERNET":
      return (
        <div style={graphStyle}>
          <IPVPNCard service={service} legacy={legacy} />
        </div>
      );
    case "IP_VPN":
      return (
        <div style={graphStyle}>
          <IPVPNCard service={service} legacy={legacy} />
        </div>
      );
    case "L2_P2P":
      return (
        <div style={graphStyle}>
          <L2P2PCard service={service} rowIndex={rowIndex} legacy={legacy} />
        </div>
      );
    case "CUSTOMER_LINE":
      if (singleService) {
        return (
          <div style={graphStyle}>
            <CustomerLineOnAccCard service={service} legacy={legacy} />
          </div>
        );
      }
      return (
        <div style={graphStyle}>
          <CustomerLineCard service={service} legacy={legacy} />
        </div>
      );
    default:
      return null;
  }
};

const getEquipmentCapabilitesFromService = (
  service: Service,
): string[] | undefined => {
  const equipment = service?.resource_equipment_ports?.[0]?.resource_equipment;
  if (equipment) {
    return equipment.capability;
  }
  return undefined;
};

const CustomerLineCard = ({
  service,
  legacy,
}: {
  service: Service;
  legacy?: boolean;
}) => {
  const equipmentCapabilities = getEquipmentCapabilitesFromService(service);
  if (equipmentCapabilities?.includes("L2")) {
    return CustomerLineOnAggCard({ service, legacy });
  }
  return CustomerLineOnAccCard({ service, legacy });
};

const CustomerLineOnAggCard = ({
  service,
  legacy,
}: {
  service: Service;
  legacy?: boolean;
}) => {
  return (
    <div className="ServiceTopologyContainer">
      <table className="ServiceTopologyTableExpanded">
        <thead>
          <tr className="ServiceTopologyTableHeaderRow">
            <th>CPE</th>
            <th>{service?.display_name}</th>
          </tr>
        </thead>
        <tbody>
          <tr className="ServiceTopologyTableBodyRow">
            <td className="ServiceTopologyTableCell">
              <CpeHouseIcon />
            </td>
            <td className="ServiceTopologyTableCell">
              <div className="ServiceTopologyFlexCenter">
                <hr className="ServiceTopologyHrLine" />
                {legacy ? (
                  service?.resource_equipment_ports ? (
                    <LegacyOperationalStatus
                      interfaceName={
                        service?.resource_equipment_ports[0].display_name
                      }
                      mgmtIp={
                        service?.resource_equipment_ports[0].resource_equipment
                          ?.loopback_ip ?? ""
                      }
                    />
                  ) : null
                ) : (
                  <OperationalStatusCustline serviceId={service.id} />
                )}
              </div>
            </td>
          </tr>
          {service?.resource_equipment_ports && (
            <tr className="ServiceTopologyTableFooterRow">
              <td />
              <td />
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

const CustomerLineOnAccCard = ({
  service,
  legacy,
}: {
  service: Service;
  legacy?: boolean;
}) => {
  return (
    <table className="ServiceTopologyTableExpanded">
      <thead>
        <tr className="ServiceTopologyTableHeaderRow">
          <th>CPE</th>
          <th>{service?.display_name}</th>
          <th style={{ textAlign: "right", paddingRight: "20px" }}>ACC</th>
        </tr>
      </thead>
      <tbody>
        <tr className="ServiceTopologyTableBodyRow">
          <td className="ServiceTopologyTableCell">
            <CpeHouseIcon />
          </td>
          <td className="ServiceTopologyTableCell">
            <div className="ServiceTopologyFlexCenter">
              <hr className="ServiceTopologyHrLine" />
            </div>
          </td>
          <td className="ServiceTopologyTableCell">
            <div className="ServiceTopologyFlexCenter">
              <hr className="ServiceTopologyHrLine" />
              {legacy ? (
                service?.resource_equipment_ports ? (
                  <LegacyOperationalStatus
                    interfaceName={
                      service?.resource_equipment_ports[0].display_name
                    }
                    mgmtIp={
                      service?.resource_equipment_ports[0].resource_equipment
                        ?.loopback_ip ?? ""
                    }
                  />
                ) : null
              ) : (
                <OperationalStatusCustline serviceId={service.id} />
              )}
              <SwitchIcon />
            </div>
          </td>
        </tr>
        {service?.resource_equipment_ports && (
          <tr className="ServiceTopologyTableFooterRow">
            <td />
            <td style={{ padding: 10, position: "relative" }}>
              {shortenInterfaceName(
                service?.resource_equipment_ports[0].display_name,
              )}
              <TextLinkIcon />
            </td>
            <td style={{ padding: 10 }}>
              <EquipmentSummaryWithPopover
                name={
                  service?.resource_equipment_ports[0].resource_equipment
                    ?.host_name
                }
                id={service?.resource_equipment_ports[0].resource_equipment?.id}
              />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

const L2P2PCard = ({
  service,
  rowIndex,
  legacy,
}: {
  service: Service;
  rowIndex: number;
  legacy?: boolean;
}) => {
  //check if there is a port connected to the service with the rowIndex, if so use it
  let port: ResourceEquipmentPort | undefined = undefined;
  if (
    service?.resource_equipment_ports &&
    service.resource_equipment_ports[rowIndex]
  ) {
    port = service.resource_equipment_ports[rowIndex];
  }
  let resourceEquipment: ResourceEquipment | undefined = undefined;
  if (port) {
    resourceEquipment = port.resource_equipment;
  }

  return (
    <table className="ServiceTopologyTable">
      <thead>
        <tr className="ServiceTopologyTableHeaderRow">
          <th />
          <th>AGG</th>
          <th />
          <th>{service?.display_name}</th>
        </tr>
      </thead>
      <tbody>
        <tr className="ServiceTopologyTableBodyRow">
          <td className="ServiceTopologyTableCell">
            <div className="ServiceTopologyFlexCenter">
              <hr className="ServiceTopologyHrLine" />
            </div>
          </td>
          <td className="ServiceTopologyTableCell">
            <div className="ServiceTopologyFlexCenter">
              <hr className="ServiceTopologyHrLine" />
              {legacy ? (
                <LegacyOperationalStatus
                  interfaceName={port?.display_name ?? ""}
                  mgmtIp={resourceEquipment?.loopback_ip ?? ""}
                />
              ) : (
                <OperationalStatusL2line serviceId={service.id} />
              )}
              <MPLSRouterIcon />
              <hr className="ServiceTopologyHrLine" />
            </div>
          </td>
          <td className="ServiceTopologyTableCell">
            <hr className="ServiceTopologyHrLine" />
          </td>
          <td className="ServiceTopologyTableCell">
            <CloudIcon />
          </td>
        </tr>
        {service?.resource_equipment_ports && (
          <tr className="ServiceTopologyTableFooterRow">
            <td style={{ padding: 10, position: "relative" }}>
              {shortenInterfaceName(port?.display_name ?? "unknown")}
              <TextLinkIcon />
            </td>
            <td style={{ padding: 10 }}>
              <EquipmentSummaryWithPopover
                name={resourceEquipment?.host_name}
                id={resourceEquipment?.id}
              />
            </td>
            <td />
            <td />
          </tr>
        )}
      </tbody>
    </table>
  );
};
const IPVPNCard = ({
  service,
  legacy,
}: {
  service: Service;
  legacy?: boolean;
}) => {
  const OperationalStatusLine = () => {
    if (legacy) {
      return (
        <LegacyOperationalStatus
          interfaceName={
            service?.resource_equipment_ports
              ? service.resource_equipment_ports[0].display_name
              : ""
          }
          mgmtIp={
            service?.resource_equipment_ports
              ? (service.resource_equipment_ports[0].resource_equipment
                  ?.loopback_ip ?? "")
              : ""
          }
        />
      );
    }
    if (service.service_type === "INTERNET") {
      return <OperationalStatusInternetline serviceId={service.id} />;
    }
    return <OperationalStatusIpVpnline serviceId={service.id} />;
  };
  return (
    <table className="ServiceTopologyTableExpanded">
      <thead>
        <tr className="ServiceTopologyTableHeaderRow">
          <th />
          <th>SE</th>
          <th />
          <th>{service?.display_name}</th>
        </tr>
      </thead>
      <tbody>
        <tr className="ServiceTopologyTableBodyRow">
          <td className="ServiceTopologyTableCell">
            <div className="ServiceTopologyFlexCenter">
              <hr className="ServiceTopologyHrLine" />
            </div>
          </td>
          <td className="ServiceTopologyTableCell">
            <div className="ServiceTopologyFlexCenter">
              <hr className="ServiceTopologyHrLine" />
              <OperationalStatusLine />
              <ServiceRouterIcon />
              <hr className="ServiceTopologyHrLine" />
            </div>
          </td>
          <td className="ServiceTopologyTableCell" style={{ width: 30 }}>
            <hr className="ServiceTopologyHrLine" />
          </td>
          <td className="ServiceTopologyTableCell">
            <div className="ServiceTopologyFlexCenter">
              <hr className="ServiceTopologyHrLine" />
              <CloudIcon />
              <hr
                className="ServiceTopologyHrLine"
                style={{ border: "0px solid black" }}
              />
            </div>
          </td>
        </tr>
        {service?.resource_equipment_ports && (
          <tr className="ServiceTopologyTableFooterRow">
            <td style={{ padding: 10, position: "relative" }}>
              {shortenInterfaceName(
                service?.resource_equipment_ports[0].display_name,
              )}
              <TextLinkIcon />
            </td>
            <td style={{ padding: 10 }}>
              <EquipmentSummaryWithPopover
                name={
                  service?.resource_equipment_ports[0].resource_equipment
                    ?.host_name
                }
                id={service?.resource_equipment_ports[0].resource_equipment?.id}
              />
            </td>
            <td />
            <td style={{ padding: 10 }}>
              <VrfDisplayName service={service} />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

const VrfDisplayName = ({ service }: { service: Service }) => {
  return service?.parameters?.find((param) => param.name === "VRF")?.value;
};

const ServiceTopologyTree = ({ id }: { id: string }) => {
  const [serviceTree, setServiceTree] = useState<
    CfServiceDescription | undefined
  >(undefined);
  const [services, setServices] = useState<Service[][]>([]);
  const [isRoot, setIsRoot] = useState(true);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      const token = await acquireAccessToken();
      OpenAPI.TOKEN = token;
      const input: CfsInventoryServicesGetServiceDescriptionData = {
        serviceId: id,
      };
      const response =
        (await CfsInventoryServicesService.cfsInventoryServicesGetServiceDescription(
          input,
        )) as CfGetServiceDescriptionResponse;
      setServiceTree(response.root);
    };
    fetchData();
    return () => {
      isMounted = false;
    };
  }, [id]);

  useEffect(() => {
    const fetchServices = async () => {
      if (serviceTree) {
        const { services: flatServices, isRoot } =
          await flattenServiceTree(serviceTree);
        setServices(flatServices);
        setIsRoot(isRoot);
      }
    };

    fetchServices();
  }, [serviceTree]);
  // sort the order of the services in the tree
  // sort the outer array based on the first element of the inner arrays display_name
  services.sort((a, b) => {
    if (a[0]?.display_name < b[0]?.display_name) {
      return -1;
    }
    if (a[0]?.display_name > b[0]?.display_name) {
      return 1;
    }
    return 0;
  });

  return (
    <Box display="flex" justifyContent="center">
      <table className="ServiceTopologyTable">
        <tbody>
          {services.map((service, rowIndex) => (
            <tr key={id}>
              {isRoot && rowIndex === 0 && (
                <td
                  rowSpan={services.length}
                  key={serviceTree?.self?.id || ""}
                  style={{ padding: 0, margin: 0 }}
                >
                  <ServiceTopologyCard
                    id={serviceTree?.self?.id || ""}
                    rowIndex={0}
                  />
                </td>
              )}
              {services.length > 1 && rowIndex === 0 && (
                <td rowSpan={services.length} className="line-cell">
                  <div className="line"></div>
                </td>
              )}
              <td>
                <table className="ServiceTopologyTable">
                  <tbody>
                    <tr>
                      {service.map((service) => (
                        <td key={service.id} style={{ padding: 0, margin: 0 }}>
                          <ServiceTopologyCard id={service.id} rowIndex={0} />
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  );
};

const flattenServiceTree = async (
  tree: CfServiceDescription,
): Promise<{ services: Service[][]; isRoot: boolean }> => {
  const result: Service[][] = [];
  let isRoot = true;
  if (!tree.self) {
    return { services: result, isRoot };
  }
  if (!tree.outbound && !tree.inbound) {
    return { services: result, isRoot };
  }

  for (const inboundLink of tree.inbound || []) {
    if (
      inboundLink.target_service &&
      serviceTypeInGraph(inboundLink.target_service.self?.service_type)
    ) {
      isRoot = false;
      break;
    }
  }
  if (isRoot) {
    // root of tree allowed multiple children but none upstream
    let numSubServices = 0;
    for (const outboundLink of tree.outbound || []) {
      if (
        outboundLink.target_service &&
        serviceTypeInGraph(outboundLink.target_service.self?.service_type)
      ) {
        numSubServices++;
        if (numSubServices > maxServicesShown) {
          const errorMessage = `More than ${maxServicesShown} services found for root service, only displaying the first ${maxServicesShown}`;
          toast.error(errorMessage);
          console.error(errorMessage);
          return { services: result, isRoot };
        }
        const subService = flattenServiceOutbound(outboundLink.target_service);
        result.push(subService);
      }
    }
  } else {
    // build tree in both directions but do not allow multiple services
    const subServices: Service[] = [];
    for (const inboundLink of tree.inbound || []) {
      if (
        inboundLink.target_service &&
        serviceTypeInGraph(inboundLink.target_service.self?.service_type)
      ) {
        const subService = await flattenServiceInbound(
          inboundLink.target_service,
        );
        subServices.push(...subService);
      }
    }
    subServices.push(tree.self as Service);
    for (const outboundLink of tree.outbound || []) {
      let graphableOutboundServices = 0;
      if (
        outboundLink.target_service &&
        serviceTypeInGraph(outboundLink.target_service.self?.service_type)
      ) {
        graphableOutboundServices++;
        if (graphableOutboundServices > 1) {
          console.log(
            "Multiple outbound services found for non root service",
            tree.self?.id,
          );
          return { services: result, isRoot };
        }
        const subService = flattenServiceOutbound(outboundLink.target_service);
        subServices.push(...subService);
      }
    }
    result.push(subServices);
  }
  return { services: result, isRoot };
};

const flattenServiceOutbound = (tree: CfServiceDescription): Service[] => {
  const result: Service[] = [];

  const dfs = (service: CfServiceDescription) => {
    if (service.self) {
      result.push(service.self as Service);
    }
    service.outbound?.forEach((outboundLink) => {
      if (
        outboundLink.target_service &&
        serviceTypeInGraph(outboundLink.target_service.self?.service_type)
      ) {
        dfs(outboundLink.target_service);
      }
    });
  };

  dfs(tree);
  return result;
};

const flattenServiceInbound = async (
  service: CfServiceDescription,
): Promise<Service[]> => {
  const result: Service[] = [];
  const dfs = async (service: CfServiceDescription) => {
    if (service.self) {
      result.push(service.self as Service);
    }
    const tree = await fetchInboundService(service.self?.id);
    if (tree?.inbound) {
      let graphableInboundServices = 0;
      for (const inboundLink of tree.inbound) {
        if (
          inboundLink.target_service &&
          serviceTypeInGraph(inboundLink.target_service.self?.service_type)
        ) {
          graphableInboundServices++;
          if (graphableInboundServices > 1) {
            console.log(
              "Multiple inbound services found for non root service",
              service.self?.id,
            );
            return;
          }
          await dfs(inboundLink.target_service);
        }
      }
    }
  };

  await dfs(service);
  return result.reverse();
};

const fetchInboundService = async (
  id: string | undefined,
): Promise<CfServiceDescription | null> => {
  if (id === undefined) {
    return null;
  }
  const token = await acquireAccessToken();
  OpenAPI.TOKEN = token;
  const input: CfsInventoryServicesGetServiceDescriptionData = {
    serviceId: id,
  };
  const response =
    (await CfsInventoryServicesService.cfsInventoryServicesGetServiceDescription(
      input,
    )) as CfGetServiceDescriptionResponse;
  return response.root ?? null;
};

const buildGraph = (
  servicePairs: ServicePair[],
  // Prune the graph back to the first common service in the graphs
  prune?: boolean,
) => {
  // Build graph from service pairs starting at the customer line.
  // since we build a graph from each customer line, do not allow a customer line to be included other than at the start
  const result: Service[][] = [];
  const visited = new Set<string>();
  const startServiceType = "CUSTOMER_LINE";

  const dfs = (service: Service, hasCustomerLine: boolean): Service[] => {
    if (visited.has(service.id)) return [];
    if (service.service_type === "CUSTOMER_LINE" && hasCustomerLine) return [];

    visited.add(service.id);
    const graph: Service[] = [service];
    if (service.service_type === "CUSTOMER_LINE") {
      hasCustomerLine = true;
    }

    const connectedServices = servicePairs
      .filter(
        (pair) =>
          pair.service_a?.id === service.id ||
          pair.service_b?.id === service.id,
      )
      .map((pair) =>
        pair.service_a?.id === service.id ? pair.service_b : pair.service_a,
      );

    connectedServices.forEach((connectedService) => {
      if (connectedService === undefined) return;
      if (!visited.has(connectedService?.id)) {
        graph.push(...dfs(connectedService, hasCustomerLine));
      }
    });
    return graph;
  };

  const startServices = servicePairs
    .filter((pair) => pair.service_a?.service_type === startServiceType)
    .map((pair) => pair.service_a);

  startServices.forEach((service) => {
    if (service === undefined) return;
    visited.clear(); // Clear visited set for each CUSTOMER_LINE
    const graph = dfs(service, false);
    result.push(graph);
  });

  // find first common service in the graphs and prune the services back to this service
  if (prune && result.length > 1) {
    const commonService = result
      .reduce<Service[]>((acc: Service[], val: Service[]) => {
        return acc.filter((service) => val.includes(service));
      }, result[0])
      .reverse();
    result.forEach((graph, index) => {
      const commonServiceIndex = graph.findIndex(
        (service) => service === commonService[0],
      );
      if (commonServiceIndex !== -1) {
        if (index === 0) {
          graph.splice(commonServiceIndex); // Exclude the common service in first pruned graph
        } else {
          graph.splice(commonServiceIndex + 1); // Include the common service in the subsequent pruned graphs
        }
      }
    });
  }

  return result.reverse();
};

const ServiceTopology = ({
  services,
  servicePairs,
  id,
}: ServiceTopologyProps) => {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Box position="relative">
      <IconButton
        color="primary"
        onClick={handleExpandClick}
        style={{ position: "absolute", top: 0, right: 0 }}
      >
        {expanded ? <CloseFullscreenOutlined /> : <OpenInFull />}
      </IconButton>
      <h2>Service Topology</h2>
      {expanded ? (
        <MemoizedServiceTopologyTree id={id} />
      ) : (
        <MemoizedServiceTopologySimple
          services={services}
          servicePairs={servicePairs}
        />
      )}
    </Box>
  );
};

export const ServiceTopologySimple = ({
  services,
  servicePairs,
  legacy,
}: {
  services: Service[] | null;
  servicePairs: ServicePair[] | null;
  legacy?: boolean;
}) => {
  let singleService = false;
  let graph;
  if (!servicePairs) {
    if (!services || services.length != 1) {
      return null;
    }
    graph = services.map((service) => [service]);
    singleService = true;
  } else {
    graph = buildGraph(servicePairs, legacy);
  }
  // find longest graph and pad the others with empty arrays
  const maxLength = Math.max(...graph.map((g) => g.length));
  if (maxLength === 0) {
    return null;
  }
  return (
    <>
      <Box display="flex" justifyContent="center">
        <table style={{ padding: 0, margin: 0, borderCollapse: "collapse" }}>
          <tbody>
            {graph.reverse().map((custLines, rowIndex) => (
              <tr key={custLines[0].id} className="ServiceTopologyTableBodyRow">
                {custLines.map((service) => (
                  <td key={service.id} style={{ padding: 0, margin: 0 }}>
                    {legacy ? (
                      <LegacyServiceTopologyCard
                        service={service}
                        rowIndex={0} // rowIndex is not used in legacy mode
                        singleService={singleService}
                      />
                    ) : (
                      <ServiceTopologyCard
                        id={service.id}
                        rowIndex={rowIndex}
                        singleService={singleService}
                      />
                    )}
                  </td>
                ))}
                {/* Add empty tds to pad the row to maxLength */}
                {Array.from({ length: maxLength - custLines.length }).map(
                  (_, index) => (
                    <td
                      key={`empty-${index}`}
                      className="ServiceTopologyTableCell"
                    >
                      <div className="ServiceTopologyFlexCenter">
                        <hr className="MultiServiceTopologyHrLine" />
                      </div>
                    </td>
                  ),
                )}
                {/* Add connecting line between lines when more than one custline exists for this service */}
                {graph.length > 1 && (
                  <td className="ServiceTopologyTableCell">
                    <div className="ServiceTopologyFlexCenter">
                      <hr className="MultiServiceTopologyHrLine" />
                    </div>
                  </td>
                )}
                {graph.length > 1 && rowIndex === 0 && (
                  <td rowSpan={custLines.length} className="line-cell">
                    <div className="line"></div>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    </>
  );
};

const MemoizedServiceTopologyTree = React.memo(ServiceTopologyTree);
const MemoizedServiceTopologySimple = React.memo(ServiceTopologySimple);

const shortenInterfaceName = (displayName: string): string => {
  return displayName
    .replace(/FastEthernet/g, "Fa")
    .replace(/GigabitEthernet/g, "Gi")
    .replace(/TenGigE/g, "Ten")
    .replace(/XGigabitEthernet/g, "Ten")
    .replace(/TwentyFiveGigE/g, "Twe")
    .replace(/FortyGigE/g, "For")
    .replace(/HundredGigE/g, "Hun")
    .replace(/PW-Ether/g, "PE")
    .replace(/Bundle-Ether/g, "BE");
};

export default ServiceTopology;
